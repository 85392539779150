import type React from "react";
import { useCallback, useEffect, useState } from "react";
import type { OlympiadProblemDetails, TimestampedResponse } from "shared-types";
import LatexRenderer from "./LatexRenderer";

interface OlympiadProblemViewProps {
  problem: OlympiadProblemDetails & { problemId: string; label: string };
  onUpdateAnswer: (answer: string) => void;
  onSubmit: (answer: string) => void;
  onSkip: () => void;
  currentAnswer: string;
  responses: TimestampedResponse[];
}

const OlympiadProblemView: React.FC<OlympiadProblemViewProps> = ({
  problem,
  onUpdateAnswer,
  onSubmit,
  onSkip,
  currentAnswer,
}) => {
  const [answer, setAnswer] = useState("");
  const [solutionFile, setSolutionFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  // Update answer when currentAnswer changes
  useEffect(() => {
    setAnswer(currentAnswer || "");
  }, [currentAnswer]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setAnswer(value);
    onUpdateAnswer(value);
  };

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        setSolutionFile(file);
        // Create preview URL for PDFs or images
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
      }
    },
    [],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (answer.trim() !== "" || solutionFile) {
      // TODO: Handle file upload with answer submission
      // For now, just submit the text answer
      onSubmit(answer.trim());
    }
  };

  // Cleanup preview URL on unmount
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div className="bg-white p-4 rounded shadow">
      <strong className="text-lg">Problem {problem.label}:</strong>
      <div className="mt-2">
        <LatexRenderer latex={problem.statement} />
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-4">
          <label htmlFor="answer-input" className="block font-medium mb-2">
            Write your solution:
          </label>
          <textarea
            id="answer-input"
            value={answer}
            onChange={handleTextChange}
            className="w-full min-h-[200px] font-mono border rounded p-3
              focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your solution here, or upload a file below..."
          />
          {problem.solutionGuidelines && (
            <div className="mt-2 text-sm text-gray-600">
              <strong>Guidelines:</strong> {problem.solutionGuidelines}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="solution-file" className="block font-medium mb-2">
            Upload solution file (PDF or image):
          </label>
          <input
            id="solution-file"
            type="file"
            accept=".pdf,image/*"
            onChange={handleFileUpload}
            className="mb-2"
          />
          {previewUrl && (
            <div className="mt-2 p-2 border rounded">
              {solutionFile?.type === "application/pdf" ? (
                <div className="text-sm text-gray-600">
                  PDF uploaded: {solutionFile.name}
                </div>
              ) : (
                <img
                  src={previewUrl}
                  alt="Solution preview"
                  className="max-w-full h-auto rounded"
                />
              )}
            </div>
          )}
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
              transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            disabled={!answer.trim() && !solutionFile}
          >
            Submit Solution
          </button>
          <button
            type="button"
            onClick={onSkip}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition-colors"
          >
            Skip
          </button>
        </div>
      </form>
    </div>
  );
};

export default OlympiadProblemView;
