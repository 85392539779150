import type React from "react";
import LatexRenderer from "./LatexRenderer";

interface PracticeNoteSectionProps {
  title: string;
  children: React.ReactNode;
}

const PracticeNoteSection: React.FC<PracticeNoteSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      {children}
    </div>
  );
};

export default PracticeNoteSection;
