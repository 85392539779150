import type React from "react";
import type {
  AIMEProblemDetails,
  AMCProblemDetails,
  OlympiadProblemDetails,
  ProblemDetails,
  ShortAnswerProblemDetails,
} from "../types";
import InlineEditableOptions from "./InlineEditableOptions";
import LatexRenderer from "./LatexRenderer";

interface ProblemTypeContentProps {
  problem: ProblemDetails;
  mode: "view" | "edit";
  onUpdate?: (field: string, value: string) => void;
}

const ProblemTypeContent: React.FC<ProblemTypeContentProps> = ({
  problem,
  mode,
  onUpdate,
}) => {
  if (problem.type === "multiple_choice") {
    const mcProblem = problem as AMCProblemDetails;

    // Use InlineEditableOptions for both view and edit modes
    return (
      <InlineEditableOptions
        options={mcProblem.options}
        correctAnswer={mcProblem.correctAnswer}
        onOptionsChange={(newOptions: Record<string, string>) => {
          onUpdate?.("options", JSON.stringify(newOptions));
        }}
        onCorrectAnswerChange={(newAnswer: string) => {
          onUpdate?.("correctAnswer", newAnswer);
        }}
      />
    );
  }

  if (problem.type === "short_answer") {
    const saProblem = problem as ShortAnswerProblemDetails;

    if (mode === "view") {
      return (
        <div className="mt-2">
          <h4 className="font-semibold">Correct Answer:</h4>
          <p>{saProblem.correctAnswer || "No correct answer set"}</p>
        </div>
      );
    }

    return (
      <div>
        <label htmlFor="correctAnswer" className="block font-bold mb-1">
          Correct Answer
        </label>
        <input
          type="text"
          id="correctAnswer"
          name="correctAnswer"
          value={saProblem.correctAnswer}
          onChange={(e) => onUpdate?.("correctAnswer", e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
    );
  }

  if (problem.type === "essay") {
    const essayProblem = problem as OlympiadProblemDetails;

    if (mode === "view") {
      return (
        <div className="mt-2">
          <h4 className="font-semibold">Solution Guidelines:</h4>
          <p>
            {essayProblem.solutionGuidelines || "No solution guidelines set"}
          </p>
        </div>
      );
    }

    return (
      <div>
        <label htmlFor="solutionGuidelines" className="block font-bold mb-1">
          Solution Guidelines
        </label>
        <textarea
          id="solutionGuidelines"
          name="solutionGuidelines"
          value={essayProblem.solutionGuidelines || ""}
          onChange={(e) => onUpdate?.("solutionGuidelines", e.target.value)}
          className="w-full p-2 border rounded"
          rows={3}
        />
      </div>
    );
  }

  return null;
};

export default ProblemTypeContent;
