import { Edit } from "lucide-react";
import type React from "react";
import { useNavigate } from "react-router-dom";
import type { Exam } from "shared-types";
import { useChallengeFiltering } from "../hooks/useChallengeFiltering";
import type {
  ChallengeData,
  GradedChallenge,
  ProgressIndicatorRowProps,
} from "../types";
import ProgressToken from "./ProgressToken";

const ProgressIndicatorRow: React.FC<ProgressIndicatorRowProps> = ({
  exam,
  isAdminMode,
  challenges,
  gradedChallenges,
  viewAsUserProfile,
  isViewingAsOtherUser,
}) => {
  const navigate = useNavigate();
  const {
    findChallengeForButton,
    getChallengeTypeDisplay,
    getConstructionReason,
  } = useChallengeFiltering();

  const handleChallengeClick = (challengeId?: string) => {
    if (!challengeId || isViewingAsOtherUser) return;
    navigate(`/challenge/${challengeId}`);
  };

  const progressRanges = ["1-10", "1-10 (B)", "11-15", "16-20", "21-25"];
  const challengeTypes = [
    "firstTen",
    "firstTen (B)",
    "11-15",
    "16-20",
    "21-25",
  ];

  const getTokenTooltip = (
    challengeType: string,
    challenge?: ChallengeData,
    gradedChallenge?: GradedChallenge,
  ): string => {
    const viewedUserName = viewAsUserProfile?.name?.split(" ")[0] || "User";
    const prefix = isViewingAsOtherUser
      ? `${viewedUserName} has `
      : "You have ";

    if (!challenge) {
      return `Under construction - ${getConstructionReason(exam, challengeType)}`;
    }

    if (!gradedChallenge) {
      return `${prefix}not attempted the ${getChallengeTypeDisplay(
        challengeType,
      )} challenge yet`;
    }

    switch (gradedChallenge.status) {
      case "worked_on":
        return `${prefix}taken the ${getChallengeTypeDisplay(
          challengeType,
        )} - needs answer checking!`;
      case "answer_checked":
        return `${prefix}completed and reviewed answers on the ${getChallengeTypeDisplay(
          challengeType,
        )}`;
      case "reviewed":
        return `${prefix}demonstrated mastery of the ${getChallengeTypeDisplay(
          challengeType,
        )}!`;
      default:
        return "Challenge status unknown";
    }
  };

  return (
    <div className="flex items-center p-2 border-b border-gray-200 hover:bg-gray-50">
      <div className="flex space-x-2">
        {progressRanges.map((range, index) => {
          const challenge = findChallengeForButton(
            challenges,
            exam,
            challengeTypes[index],
          );

          const gradedChallenge = challenge
            ? gradedChallenges.find((gc) => gc.challengeId === challenge.id)
            : undefined;

          return (
            <ProgressToken
              key={range}
              challengeId={challenge?.id || null}
              gradedChallenge={gradedChallenge}
              onClick={() => handleChallengeClick(challenge?.id)}
              isDisabled={isViewingAsOtherUser}
              tooltip={getTokenTooltip(
                challengeTypes[index],
                challenge,
                gradedChallenge,
              )}
              data-testid={`progress-token-${index}`}
            />
          );
        })}
      </div>
      <div className="flex-grow ml-4">
        <div className="text-sm font-medium text-gray-800">{exam.name}</div>
        <div className="text-xs text-gray-500">{exam.year}</div>
      </div>
      {isAdminMode && (
        <button
          type="button"
          onClick={() => navigate(`/exam/${exam.id}`)}
          className="ml-4 bg-blue-500 text-white px-2 py-1 rounded text-xs hover:bg-blue-600 transition-colors flex items-center"
          aria-label={`Edit ${exam.name}`}
        >
          <Edit size={12} className="mr-1" />
          Edit
        </button>
      )}
    </div>
  );
};

export default ProgressIndicatorRow;
