import { useCallback, useEffect, useRef, useState } from "react";
import type {
  ChallengeRun,
  ProblemReference,
  ProblemTimer,
  TimestampedResponse,
} from "shared-types";
import useActionRecorder from "./useActionRecorder";
import { useExamStatePersistence } from "./useExamStatePersistence";

// Initial timers creation helper
const createInitialTimers = (
  problems: ProblemReference[],
): Record<string, ProblemTimer> => {
  const timers: Record<string, ProblemTimer> = {};
  for (const problem of problems) {
    timers[problem.label] = {
      firstTimer: 0,
      secondTimer: 0,
      firstTimerLocked: false,
    };
  }
  return timers;
};

const useExamNavigation = (
  challengeRun: ChallengeRun,
  challengeProblems: ProblemReference[],
) => {
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [problemResponses, setProblemResponses] = useState<
    Record<string, TimestampedResponse[]>
  >({});
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<string, string | null>
  >({});
  const [problemTimers, setProblemTimers] = useState<
    Record<string, ProblemTimer>
  >({});
  const [totalTimePaused, setTotalTimePaused] = useState(0);
  const [visitedProblems, setVisitedProblems] = useState<boolean[]>([]);
  const [pauseAfterSubmission, setPauseAfterSubmission] = useState(false);
  const [autoSubmit, setAutoSubmit] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showAllProblems, setShowAllProblems] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [timersInitialized, setTimersInitialized] = useState(false);

  const lastUpdateRef = useRef<number>(0);
  const rafRef = useRef<number | undefined>(undefined);

  const {
    actionLog,
    recordNavigateAway,
    recordOpenProblem,
    recordSubmitAnswer,
    recordSkipProblem,
    loadActionsFromAPI,
    seedInitialAction,
    recordViewAllProblems,
    recordViewSingleProblem,
  } = useActionRecorder(challengeRun.id);

  const problems = challengeProblems;

  // Initialize timers once when problems are available
  useEffect(() => {
    if (problems.length > 0 && !timersInitialized) {
      const initialTimers = createInitialTimers(problems);
      setProblemTimers(initialTimers);
      setVisitedProblems(new Array(problems.length).fill(false));
      setTimersInitialized(true);
    }
  }, [problems, timersInitialized]);

  // Compute current timer state based on action log
  const computeTimerUpdates = useCallback(() => {
    if (!actionLog || actionLog.length === 0) {
      return null;
    }

    const now = Date.now();
    const timers = createInitialTimers(problems);
    let currentProblemLabel: string | null = null;
    let lastActionTime = new Date(challengeRun.startedAt).getTime();
    let totalPausedTime = 0;

    const sortedActions = [...actionLog].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
    );

    for (const action of sortedActions) {
      const actionTime = new Date(action.timestamp).getTime();
      const timeDiff = actionTime - lastActionTime;

      if (currentProblemLabel === null) {
        totalPausedTime += timeDiff;
      } else {
        const timer = timers[currentProblemLabel];
        if (timer) {
          if (timer.firstTimerLocked) {
            timer.secondTimer += timeDiff;
          } else {
            timer.firstTimer += timeDiff;
          }
        }
      }

      switch (action.type) {
        case "openProblem":
          currentProblemLabel = action.problemLabel || null;
          break;
        case "submitAnswer":
          if (currentProblemLabel && timers[currentProblemLabel]) {
            timers[currentProblemLabel].firstTimerLocked = true;
          }
          break;
        case "navigateAway":
          currentProblemLabel = null;
          break;
        default:
          break;
      }

      lastActionTime = actionTime;
    }

    // Account for time since last action
    const timeSinceLastAction = now - lastActionTime;
    if (currentProblemLabel === null) {
      totalPausedTime += timeSinceLastAction;
    } else {
      const timer = timers[currentProblemLabel];
      if (timer) {
        if (timer.firstTimerLocked) {
          timer.secondTimer += timeSinceLastAction;
        } else {
          timer.firstTimer += timeSinceLastAction;
        }
      }
    }

    return {
      timers,
      totalPausedTime,
    };
  }, [actionLog, challengeRun.startedAt, problems]);

  // Update timers using requestAnimationFrame
  useEffect(() => {
    const updateTimers = (timestamp: number) => {
      // Only update if more than 1000ms (1 second) has passed
      if (timestamp - lastUpdateRef.current >= 1000) {
        const updates = computeTimerUpdates();
        if (updates) {
          setProblemTimers(updates.timers);
          setTotalTimePaused(updates.totalPausedTime);
        }
        lastUpdateRef.current = timestamp;
      }

      // Schedule next update
      rafRef.current = requestAnimationFrame(updateTimers);
    };

    // Start the animation frame loop
    rafRef.current = requestAnimationFrame(updateTimers);

    // Cleanup function
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [computeTimerUpdates]);

  const navigateToProblem = useCallback(
    (newIndex: number) => {
      if (newIndex >= 0 && newIndex < problems.length) {
        recordNavigateAway(problems[currentProblemIndex].label);
        setCurrentProblemIndex(newIndex);
        setVisitedProblems((prev) => {
          const updated = [...prev];
          updated[newIndex] = true;
          return updated;
        });
        recordOpenProblem(problems[newIndex].label);
      } else if (newIndex >= problems.length) {
        setIsComplete(true);
      }
    },
    [problems, currentProblemIndex, recordNavigateAway, recordOpenProblem],
  );

  const handleAnswer = useCallback(
    (answer: string) => {
      const problemLabel = problems[currentProblemIndex].label;

      if (!problemTimers[problemLabel]) {
        throw new Error(
          `Problem timer for ${problemLabel} is not initialized.`,
        );
      }

      const timestamp = Date.now();
      const response: TimestampedResponse = { answer, timestamp };

      setProblemResponses((prev) => {
        const previousResponses = prev[problemLabel] || [];
        return {
          ...prev,
          [problemLabel]: [...previousResponses, response],
        };
      });

      setSelectedAnswers((prev) => ({ ...prev, [problemLabel]: null }));
      recordSubmitAnswer(
        problemLabel,
        answer,
        problemTimers[problemLabel].firstTimer,
      );

      setProblemTimers((prev) => ({
        ...prev,
        [problemLabel]: {
          ...prev[problemLabel],
          firstTimerLocked: true,
        },
      }));

      if (pauseAfterSubmission) {
        setIsPaused(true);
      } else if (autoSubmit) {
        navigateToProblem(currentProblemIndex + 1);
      }
    },
    [
      problems,
      currentProblemIndex,
      recordSubmitAnswer,
      problemTimers,
      navigateToProblem,
      pauseAfterSubmission,
      autoSubmit,
    ],
  );

  const handleUpdateAnswer = (problemLabel: string, answer: string) => {
    setSelectedAnswers((prev) => ({ ...prev, [problemLabel]: answer }));
  };

  const handleContinue = useCallback(() => {
    setIsPaused(false);
    navigateToProblem(currentProblemIndex + 1);
  }, [currentProblemIndex, navigateToProblem]);

  const handleSkip = useCallback(() => {
    recordSkipProblem(problems[currentProblemIndex].label);
    navigateToProblem(currentProblemIndex + 1);
  }, [currentProblemIndex, problems, navigateToProblem, recordSkipProblem]);

  const handlePrevious = useCallback(() => {
    navigateToProblem(currentProblemIndex - 1);
  }, [currentProblemIndex, navigateToProblem]);

  const handleNext = useCallback(() => {
    navigateToProblem(currentProblemIndex + 1);
  }, [currentProblemIndex, navigateToProblem]);

  const toggleShowAllProblems = useCallback(() => {
    setShowAllProblems((prev) => {
      const newValue = !prev;
      if (newValue) {
        recordViewAllProblems();
      } else {
        recordViewSingleProblem(problems[currentProblemIndex].label);
      }
      return newValue;
    });
  }, [
    currentProblemIndex,
    problems,
    recordViewAllProblems,
    recordViewSingleProblem,
  ]);

  const canNavigatePrevious = currentProblemIndex > 0;
  const canNavigateNext = currentProblemIndex < problems.length - 1;

  // Use persistence hook
  useExamStatePersistence(
    challengeRun,
    {
      currentProblemIndex,
      problemResponses,
      answers,
      selectedAnswers,
      problemTimers,
      visitedProblems,
      isComplete,
      totalTimePaused,
      pauseAfterSubmission,
      autoSubmit,
      isPaused,
      showAllProblems,
    },
    {
      setCurrentProblemIndex,
      setProblemResponses,
      setAnswers,
      setSelectedAnswers,
      setProblemTimers,
      setVisitedProblems,
      setIsComplete,
      setTotalTimePaused,
      setPauseAfterSubmission,
      setAutoSubmit,
      setIsPaused,
      setShowAllProblems,
      setTimersInitialized,
    },
    seedInitialAction,
    loadActionsFromAPI,
  );

  return {
    currentProblemIndex,
    problemResponses,
    selectedAnswers,
    problemTimers,
    totalTimePaused,
    visitedProblems,
    pauseAfterSubmission,
    setPauseAfterSubmission,
    autoSubmit,
    setAutoSubmit,
    isPaused,
    actionLog,
    problems,
    navigateToProblem,
    handleAnswer,
    handleUpdateAnswer,
    handleSkip,
    handlePrevious,
    handleNext,
    handleContinue,
    showAllProblems,
    toggleShowAllProblems,
    isComplete,
    setIsComplete,
    loadActionsFromAPI,
    seedInitialAction,
    canNavigatePrevious,
    canNavigateNext,
  };
};

export { useExamNavigation };
