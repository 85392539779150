import type React from "react";
import PracticeNoteSection from "./PracticeNoteSection";
import Week1AimeSection from "./Week1AimeSection";
import Week1GeneralSection from "./Week1GeneralSection";
import Week2Section from "./Week2Section";

const PracticeNotes: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Practice Notes</h1>

      <div className="prose max-w-none">
        <PracticeNoteSection title="Practice Week 1 (February 2 - February 8)">
          <Week1GeneralSection />
          <Week1AimeSection />
        </PracticeNoteSection>

        <hr className="my-8 border-t-2 border-gray-300" />

        <PracticeNoteSection title="Practice Week 2 (February 9 - February 15)">
          <Week2Section />
        </PracticeNoteSection>
      </div>
    </div>
  );
};

export default PracticeNotes;
