import type React from "react";

const Week2Section: React.FC = () => {
  return (
    <>
      <p className="mb-6">
        For our next meeting on Monday, February 10th, we'll be working through
        the{" "}
        <a
          href="https://www.grindolympiads.com/challenge/b8C7KrQ7ErnvRZyvou7j"
          className="text-blue-600 hover:underline"
        >
          2003 Nats Sprint Practice
        </a>{" "}
        either over Zoom or independently, depending on your preference. We can
        also discuss answers from the 2002 Nats Sprint Practice during our
        session on Wednesday, February 12th.
      </p>

      <p className="mb-6">
        From 7:00-8:00pm, we'll invert last week's NYSML/ARML arrangement: we'll
        work through the actual 1984 ARML questions 1-2, followed by 1985 NYSML
        questions 3-8.
      </p>

      <p className="italic mt-8">
        Note that this page is still under construction - when it is finished it
        will contain a comprehensive review of the problems from 2001 that were
        discussed Monday.
      </p>
    </>
  );
};

export default Week2Section;
