import type { ChallengeData, Exam } from "shared-types";

export const useChallengeFiltering = () => {
  const findChallengeForButton = (
    challenges: ChallengeData[],
    exam: Exam,
    challengeType: string,
  ): ChallengeData | undefined => {
    if (challengeType === "firstTen (B)") {
      // First find the paired exam from a combined challenge
      const combinedChallenge = challenges.find(
        (ch) =>
          (ch.type === "11-15" || ch.type === "16-20" || ch.type === "21-25") &&
          ch.examIds?.includes(exam.id),
      );

      if (!combinedChallenge?.examIds) return undefined;

      // Get the other exam ID that's paired with this one
      const pairedExamId = combinedChallenge.examIds.find(
        (id) => id !== exam.id,
      );

      if (!pairedExamId) return undefined;

      // Now find the firstTen challenge for the paired exam
      return challenges.find(
        (c) => c.type === "firstTen" && c.examIds?.includes(pairedExamId),
      );
    }

    // For all other buttons, find challenges that include this exam's ID
    return challenges.find(
      (c) => c.type === challengeType && c.examIds?.includes(exam.id),
    );
  };

  const getChallengeTypeDisplay = (challengeType: string): string => {
    switch (challengeType) {
      case "firstTen":
        return "First Ten";
      case "firstTen (B)":
        return "First Ten (B)";
      case "11-15":
        return "Problems 11-15";
      case "16-20":
        return "Problems 16-20";
      case "21-25":
        return "Problems 21-25";
      default:
        return "Unknown";
    }
  };

  const getConstructionReason = (exam: Exam, challengeType: string): string => {
    if (!exam || !challengeType) return "Challenge configuration is incomplete";

    if (exam.examType === "AIME") {
      if (challengeType === "firstTen") {
        return "AIME exams don't have First Ten challenges as they only contain 15 problems";
      }
      if (challengeType === "16-20" || challengeType === "21-25") {
        return "AIME exams only contain problems 1-15";
      }
    }

    if (exam.examType === "AMC-8") {
      if (challengeType === "16-20" || challengeType === "21-25") {
        return "AMC-8 exams only contain problems 1-25";
      }
      if (challengeType.includes("(B)")) {
        return "AMC-8 exams don't have alternate year versions";
      }
    }

    if (exam.examType === "Olympiad") {
      if (challengeType === "firstTen" || challengeType.includes("11-15")) {
        return "Olympiad problems are longer format and don't follow the standard 1-25 structure";
      }
    }

    if (exam.examType === "ARML") {
      return "ARML challenges are under development - coming soon!";
    }

    return "This challenge type is still under development for this exam format";
  };

  return {
    findChallengeForButton,
    getChallengeTypeDisplay,
    getConstructionReason,
  };
};
