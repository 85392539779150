import { Download, Paintbrush, Trash2 } from "lucide-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { drawShape, drawTemporaryLine, findNearestPoint } from "./canvasUtils";
import { LINE_WIDTH_OPTIONS, SNAP_THRESHOLD, TOOLS } from "./constants";
import type { Point, Shape } from "./types";

const AnnotationTool: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [tool, setTool] = useState("pointer");
  const [color, setColor] = useState("#FF0000");
  const [fillColor, setFillColor] = useState("#FFFFFF");
  const [lineWidth, setLineWidth] = useState(3);
  const [startPos, setStartPos] = useState<Point>({ x: 0, y: 0 });
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [isFilled, setIsFilled] = useState(false);
  const [connectionPoints, setConnectionPoints] = useState<Point[]>([]);
  const [tempEndPoint, setTempEndPoint] = useState<Point | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const context = canvas.getContext("2d");
    if (!context) return;

    context.lineCap = "round";
    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    contextRef.current = context;

    redrawCanvas();
  }, [color, lineWidth]);

  const redrawCanvas = () => {
    const ctx = contextRef.current;
    const canvas = canvasRef.current;
    if (!ctx || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw all shapes
    for (const shape of shapes) {
      drawShape(ctx, shape);
    }

    // Draw temporary line if currently drawing
    if (isDrawing && tool === "line" && tempEndPoint) {
      drawTemporaryLine(ctx, startPos, tempEndPoint, color, lineWidth);
    }
  };

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (tool === "pointer") return;

    const { offsetX, offsetY } = e.nativeEvent;
    let startX = offsetX;
    let startY = offsetY;

    // Snap to nearest point if using line tool
    if (tool === "line") {
      const nearest = findNearestPoint(
        connectionPoints,
        offsetX,
        offsetY,
        SNAP_THRESHOLD,
      );
      if (nearest) {
        startX = nearest.x;
        startY = nearest.y;
      }
    }

    if (!contextRef.current) return;
    contextRef.current.beginPath();
    contextRef.current.moveTo(startX, startY);
    setStartPos({ x: startX, y: startY });

    if (tool === "pencil") {
      setShapes([
        ...shapes,
        {
          type: "pencil",
          points: [{ x: startX, y: startY }],
          color: color,
          lineWidth: lineWidth,
        },
      ]);
    }

    if (tool === "text") {
      const text = window.prompt("Enter text:", "");
      if (text) {
        setShapes([
          ...shapes,
          {
            type: "text",
            text: text,
            x: startX,
            y: startY,
            color: color,
            lineWidth: lineWidth,
          },
        ]);
        redrawCanvas();
      }
      return;
    }

    setIsDrawing(true);
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const ctx = contextRef.current;
    if (!ctx) return;

    if (tool === "pencil") {
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();

      const updatedShapes = [...shapes];
      const currentShape = updatedShapes[updatedShapes.length - 1];
      if (currentShape.points) {
        currentShape.points.push({ x: offsetX, y: offsetY });
        setShapes(updatedShapes);
      }
    } else if (tool === "line") {
      const nearest = findNearestPoint(
        connectionPoints,
        offsetX,
        offsetY,
        SNAP_THRESHOLD,
      );
      const endPoint = nearest || { x: offsetX, y: offsetY };
      setTempEndPoint(endPoint);
      redrawCanvas();
    } else {
      redrawCanvas();

      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.fillStyle = fillColor;

      if (tool === "rectangle") {
        ctx.rect(
          startPos.x,
          startPos.y,
          offsetX - startPos.x,
          offsetY - startPos.y,
        );
      } else if (tool === "circle") {
        const radius = Math.sqrt(
          (offsetX - startPos.x) ** 2 + (offsetY - startPos.y) ** 2,
        );
        ctx.arc(startPos.x, startPos.y, radius, 0, 2 * Math.PI);
      }

      if (isFilled) {
        ctx.fill();
      }
      ctx.stroke();
    }
  };

  const stopDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = e.nativeEvent;
    let endX = offsetX;
    let endY = offsetY;

    // Snap to nearest point if using line tool
    if (tool === "line") {
      const nearest = findNearestPoint(
        connectionPoints,
        offsetX,
        offsetY,
        SNAP_THRESHOLD,
      );
      if (nearest) {
        endX = nearest.x;
        endY = nearest.y;
      }

      // Add line and its endpoints to shapes and connection points
      setShapes([
        ...shapes,
        {
          type: "line",
          startX: startPos.x,
          startY: startPos.y,
          endX: endX,
          endY: endY,
          color: color,
          lineWidth: lineWidth,
        },
      ]);

      // Add new connection points
      setConnectionPoints([
        ...connectionPoints,
        { x: startPos.x, y: startPos.y },
        { x: endX, y: endY },
      ]);
    } else if (tool === "rectangle") {
      setShapes([
        ...shapes,
        {
          type: "rectangle",
          startX: startPos.x,
          startY: startPos.y,
          width: endX - startPos.x,
          height: endY - startPos.y,
          color: color,
          fillColor: fillColor,
          isFilled: isFilled,
          lineWidth: lineWidth,
        },
      ]);
    } else if (tool === "circle") {
      const radius = Math.sqrt(
        (endX - startPos.x) ** 2 + (endY - startPos.y) ** 2,
      );
      setShapes([
        ...shapes,
        {
          type: "circle",
          startX: startPos.x,
          startY: startPos.y,
          radius: radius,
          color: color,
          fillColor: fillColor,
          isFilled: isFilled,
          lineWidth: lineWidth,
        },
      ]);
    }

    setIsDrawing(false);
    setTempEndPoint(null);
    if (contextRef.current) {
      contextRef.current.closePath();
    }
    redrawCanvas();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setShapes([]);
    setConnectionPoints([]);
  };

  const downloadCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dataUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "annotation.png";
    link.href = dataUrl;
    link.click();
  };

  return (
    <div className="relative w-full h-screen">
      {/* Toolbar */}
      <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg p-2 flex gap-2 z-10">
        {TOOLS.map(({ id, Icon, label }) => (
          <button
            type="button"
            key={id}
            onClick={() => setTool(id)}
            className={`p-2 rounded hover:bg-gray-100 ${
              tool === id ? "bg-blue-100" : ""
            }`}
            title={label}
          >
            <Icon size={20} />
          </button>
        ))}
        <div className="w-px h-6 my-auto bg-gray-300" />
        <input
          type="color"
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
            if (contextRef.current) {
              contextRef.current.strokeStyle = e.target.value;
            }
          }}
          className="w-8 h-8 rounded cursor-pointer"
          title="Stroke color"
        />
        <input
          type="color"
          value={fillColor}
          onChange={(e) => setFillColor(e.target.value)}
          className="w-8 h-8 rounded cursor-pointer"
          title="Fill color"
        />
        <button
          type="button"
          onClick={() => setIsFilled(!isFilled)}
          className={`p-2 rounded hover:bg-gray-100 ${
            isFilled ? "bg-blue-100" : ""
          }`}
          title="Toggle fill"
        >
          <Paintbrush size={20} />
        </button>
        <select
          value={lineWidth}
          onChange={(e) => {
            const width = Number(e.target.value);
            setLineWidth(width);
            if (contextRef.current) {
              contextRef.current.lineWidth = width;
            }
          }}
          className="px-2 py-1 rounded border"
          title="Line width"
        >
          {LINE_WIDTH_OPTIONS.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={clearCanvas}
          className="p-2 rounded hover:bg-gray-100"
          title="Clear canvas"
        >
          <Trash2 size={20} />
        </button>
        <button
          type="button"
          onClick={downloadCanvas}
          className="p-2 rounded hover:bg-gray-100"
          title="Download"
        >
          <Download size={20} />
        </button>
      </div>

      {/* Canvas */}
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        className="absolute top-0 left-0 w-full h-full cursor-crosshair"
      />
    </div>
  );
};

export default AnnotationTool;
