import type React from "react";
import type { ProblemSet } from "shared-types";

interface ProblemSetListProps {
  problemSets: ProblemSet[];
}

const ProblemSetList: React.FC<ProblemSetListProps> = ({ problemSets }) => {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-3 text-blue-800 border-b border-gray-200 pb-2">
        Problem Sets
      </h2>

      <ul className="mb-6">
        {problemSets.map((problemSet) => (
          <li
            key={`${problemSet.title}-${problemSet.time}`}
            className="flex justify-between py-3 border-b border-gray-100 last:border-0"
          >
            <span className="font-medium">
              {problemSet.url ? (
                <a
                  href={problemSet.url}
                  className="text-blue-600 hover:underline"
                >
                  {problemSet.title}
                </a>
              ) : (
                problemSet.title
              )}
            </span>
            <span className="text-gray-600 text-sm">{problemSet.time}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProblemSetList;
