import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";

const Settings: React.FC = () => {
  const { currentUser, userProfile, logout } = useAuth();
  const originalTitle = useRef(document.title);

  const [activeTab, setActiveTab] = useState<
    "general" | "preferences" | "experimental"
  >("general");

  // Update page title
  useEffect(() => {
    document.title = "Settings - GrindOlympiads";

    return () => {
      document.title = originalTitle.current;
    };
  }, []);

  const [settings, setSettings] = useState({
    displayName: userProfile?.name || currentUser?.displayName || "",
    email: userProfile?.email || currentUser?.email || "",
    useTools: false,
    shownTests: {
      "AMC 8": true,
      "AMC 10": true,
      "AMC 12": true,
      AIME: false,
      USAMO: false,
    },
    notifications: true,
    darkMode: false,
    experimentalFeatures: false,
  });

  const [saveAlert, setSaveAlert] = useState(false);

  const updateSettings = (key: string, value: unknown) => {
    const newSettings = {
      ...settings,
      [key]: value,
    };
    setSettings(newSettings);
    setSaveAlert(true);
    setTimeout(() => setSaveAlert(false), 3000);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Settings</h1>

      <div className="flex border-b mb-4">
        <button
          type="button"
          className={`px-4 py-2 mr-2 ${activeTab === "general" ? "border-b-2 border-blue-500" : ""}`}
          onClick={() => setActiveTab("general")}
        >
          General
        </button>
        <button
          type="button"
          className={`px-4 py-2 mr-2 ${activeTab === "preferences" ? "border-b-2 border-blue-500" : ""}`}
          onClick={() => setActiveTab("preferences")}
        >
          Preferences
        </button>
        <button
          type="button"
          className={`px-4 py-2 ${activeTab === "experimental" ? "border-b-2 border-blue-500" : ""}`}
          onClick={() => setActiveTab("experimental")}
        >
          Experimental
        </button>
      </div>

      {activeTab === "general" && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Account Settings</h2>
          <div className="space-y-6">
            <div className="space-y-2">
              <label
                htmlFor="displayName"
                className="text-sm font-medium text-gray-700"
              >
                Display Name
              </label>
              <input
                id="displayName"
                type="text"
                value={settings.displayName}
                onChange={(e) => updateSettings("displayName", e.target.value)}
                placeholder="Enter your display name"
                className="w-full max-w-md p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div className="space-y-2">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={settings.email}
                onChange={(e) => updateSettings("email", e.target.value)}
                placeholder="Enter your email"
                className="w-full max-w-md p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <button
              type="button"
              onClick={logout}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >
              Logout
            </button>
          </div>
        </div>
      )}

      {activeTab === "preferences" && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Preferences</h2>
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <span>Dark Mode</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.darkMode}
                  onChange={(e) => updateSettings("darkMode", e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
              </label>
            </div>

            <div className="flex items-center justify-between">
              <span>Notifications</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings.notifications}
                  onChange={(e) =>
                    updateSettings("notifications", e.target.checked)
                  }
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
              </label>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Shown Tests</h3>
              <div className="space-y-2">
                {Object.entries(settings.shownTests).map(
                  ([testName, isShown]) => (
                    <div key={testName} className="flex items-center">
                      <input
                        type="checkbox"
                        id={testName}
                        checked={isShown}
                        onChange={(e) =>
                          updateSettings("shownTests", {
                            ...settings.shownTests,
                            [testName]: e.target.checked,
                          })
                        }
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label
                        htmlFor={testName}
                        className="ml-2 text-sm font-medium text-gray-700"
                      >
                        {testName}
                      </label>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "experimental" && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Experimental Features</h2>
          <div className="flex items-center justify-between mb-4">
            <span>Enable Experimental Features</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.experimentalFeatures}
                onChange={(e) =>
                  updateSettings("experimentalFeatures", e.target.checked)
                }
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
            </label>
          </div>
          {settings.experimentalFeatures && (
            <div className="mt-4 p-4 bg-yellow-100 rounded">
              <p className="text-yellow-700">
                Warning: Experimental features may be unstable.
              </p>
            </div>
          )}
        </div>
      )}

      {saveAlert && (
        <div className="fixed bottom-4 right-4 p-4 bg-green-100 text-green-700 rounded shadow-lg">
          Settings saved successfully!
        </div>
      )}
    </div>
  );
};

export default Settings;
