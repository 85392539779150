import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchChallenges as apiFetchChallenges,
  fetchGradedChallenges as apiFetchGradedChallenges,
  submitChallengeResponse as apiSubmitChallengeResponse,
} from "../api/challengeService";
import { useAuth } from "../contexts/AuthContext";
import type { ChallengeData, GradedChallenge } from "../types";

const useChallenges = () => {
  const [challenges, setChallenges] = useState<ChallengeData[]>([]);
  const [gradedChallenges, setGradedChallenges] = useState<GradedChallenge[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { userProfile, viewAsUserProfile } = useAuth();

  // Check if we're viewing as another user
  const isViewingAsOtherUser: boolean = useMemo(() => {
    return Boolean(
      viewAsUserProfile?.uid &&
        userProfile?.uid &&
        viewAsUserProfile.uid !== userProfile.uid,
    );
  }, [viewAsUserProfile?.uid, userProfile?.uid]);

  // Fetch challenges from the backend
  const fetchChallenges = useCallback(async () => {
    try {
      setLoading(true);
      const result = await apiFetchChallenges();
      setChallenges(result.challenges);
      setError(null);
    } catch (err) {
      console.error("Error fetching challenges:", err);
      setError("Failed to load challenges. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch graded challenges for a specific user
  const fetchGradedChallengesForUser = useCallback(async (userId: string) => {
    try {
      const result = await apiFetchGradedChallenges(userId);
      return result.gradedChallenges;
    } catch (err) {
      console.error(
        `Error fetching graded challenges for user ${userId}:`,
        err,
      );
      return [];
    }
  }, []);

  const fetchGradedChallenges = useCallback(async () => {
    const targetUserId = viewAsUserProfile?.uid;

    if (targetUserId) {
      const gradedChallengesResult =
        await fetchGradedChallengesForUser(targetUserId);
      setGradedChallenges(gradedChallengesResult);
    } else {
      setGradedChallenges([]);
    }
  }, [viewAsUserProfile?.uid, fetchGradedChallengesForUser]);

  useEffect(() => {
    fetchChallenges();
    fetchGradedChallenges();
  }, [fetchChallenges, fetchGradedChallenges]);

  // Submit challenge responses to the backend (only for current user)
  const submitChallengeResponse = async (
    challengeId: string,
    responses: { [key: string]: { answer: string; timeSpent: number } },
  ) => {
    if (isViewingAsOtherUser) {
      const error =
        "Cannot submit challenge responses while viewing as another user";
      console.error(error);
      throw new Error(error);
    }

    try {
      const result = await apiSubmitChallengeResponse(challengeId, responses);
      await fetchGradedChallenges();

      return result;
    } catch (err) {
      console.error("Error submitting challenge response:", err);
      throw err;
    }
  };

  return {
    challenges,
    gradedChallenges,
    loading,
    error,
    isViewingAsOtherUser,
    submitChallengeResponse,
  };
};

export default useChallenges;
