import { useCallback, useEffect, useState } from "react";
import type { PracticeSession } from "shared-types";
import { practiceService } from "../services/PracticeService";

interface UsePracticeResult {
  practiceSessions: PracticeSession[];
  currentSessionIndex: number;
  currentSession: PracticeSession | null;
  isLoading: boolean;
  error: string | null;
  isCompleted: boolean;
  showNotes: boolean;
  loadingZoom: boolean;
  countdown: string;
  goToPrevious: () => void;
  goToNext: () => void;
  toggleCompletion: () => void;
  sessionHasEnded: boolean;
}

export const usePractice = (initialIndex = 1): UsePracticeResult => {
  const [practiceSessions, setPracticeSessions] = useState<PracticeSession[]>(
    [],
  );
  const [currentSessionIndex, setCurrentSessionIndex] =
    useState<number>(initialIndex);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [loadingZoom, setLoadingZoom] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<string>("");

  // Get current session
  const currentSession = practiceSessions[currentSessionIndex] || null;

  // Fetch practice sessions on component mount
  useEffect(() => {
    const loadPracticeSessions = async () => {
      try {
        const sessions = await practiceService.getPracticeSessions();
        setPracticeSessions(sessions);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching practice sessions:", err);
        setError("Failed to load practice sessions. Please try again later.");
        setIsLoading(false);
      }
    };

    loadPracticeSessions();
  }, []);

  // Handle navigation
  const goToPrevious = useCallback(() => {
    if (currentSessionIndex > 0) {
      setCurrentSessionIndex(currentSessionIndex - 1);
    }
  }, [currentSessionIndex]);

  const goToNext = useCallback(() => {
    if (currentSessionIndex < practiceSessions.length - 1) {
      setCurrentSessionIndex(currentSessionIndex + 1);
    }
  }, [currentSessionIndex, practiceSessions.length]);

  // Toggle completion status
  const toggleCompletion = useCallback(() => {
    const newCompletionStatus = !isCompleted;
    setIsCompleted(newCompletionStatus);
    setShowNotes(newCompletionStatus);

    if (currentSession) {
      practiceService
        .markSessionComplete(currentSession.id, newCompletionStatus)
        .catch((err) => {
          console.error("Error marking session completion status:", err);
          // Revert UI state if API call fails
          setIsCompleted(!newCompletionStatus);
          setShowNotes(!newCompletionStatus);
        });
    }
  }, [isCompleted, currentSession]);

  // Countdown timer for future sessions
  useEffect(() => {
    if (!currentSession) return;

    // Only set up countdown for future sessions
    const sessionDate = new Date(currentSession.startTime);
    const now = new Date();

    if (sessionDate > now) {
      const timer = setInterval(() => {
        const now = new Date();
        const diff = sessionDate.getTime() - now.getTime();

        if (diff <= 0) {
          setCountdown("Starting now!");
          clearInterval(timer);
          return;
        }

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        setCountdown(`(in ${hours}h, ${minutes}m, ${seconds}s)`);
      }, 1000);

      return () => clearInterval(timer);
    }

    // Check if session has ended
    const sessionEnd = new Date(sessionDate);
    sessionEnd.setHours(sessionEnd.getHours() + 2); // Assuming 2 hour sessions

    if (now > sessionEnd) {
      setCountdown("");
    } else {
      setCountdown("(in progress)");
    }
  }, [currentSession]);

  // Fetch Zoom link for future sessions
  useEffect(() => {
    if (!currentSession) return;

    const sessionDate = new Date(currentSession.startTime);
    const now = new Date();

    // Only fetch zoom link for future sessions that don't already have one
    if (sessionDate > now && !currentSession.zoomLink) {
      setLoadingZoom(true);

      practiceService
        .getZoomLink(currentSession.id)
        .then((link) => {
          setPracticeSessions((prev) =>
            prev.map((session, idx) =>
              idx === currentSessionIndex
                ? { ...session, zoomLink: link }
                : session,
            ),
          );
          setLoadingZoom(false);
        })
        .catch((error) => {
          console.error("Error fetching zoom link:", error);
          setLoadingZoom(false);
        });
    }
  }, [currentSession, currentSessionIndex]);

  // Check if session has ended
  const sessionHasEnded = currentSession
    ? (() => {
        const sessionDate = new Date(currentSession.startTime);
        const sessionEnd = new Date(sessionDate);
        sessionEnd.setHours(sessionEnd.getHours() + 2); // Assuming 2 hour sessions
        const now = new Date();
        return now > sessionEnd;
      })()
    : false;

  return {
    practiceSessions,
    currentSessionIndex,
    currentSession,
    isLoading,
    error,
    isCompleted,
    showNotes,
    loadingZoom,
    countdown,
    goToPrevious,
    goToNext,
    toggleCompletion,
    sessionHasEnded,
  };
};
