import type React from "react";
import { useState } from "react";
import ProblemService from "../services/ProblemService";
import type { ChallengeRun, ProblemDetails, ProblemReference } from "../types";
import DraggableProblemList from "./DraggableProblemList";
import ProblemCreator from "./ProblemCreator";

interface ChallengeEditorProps {
  challengeRun: ChallengeRun;
}

const ChallengeEditor: React.FC<ChallengeEditorProps> = ({ challengeRun }) => {
  const [problems, setProblems] = useState<
    (Partial<ProblemReference> & Partial<ProblemDetails>)[]
  >(challengeRun.challenge.problems);
  const [error, setError] = useState<string | null>(null);
  const [syncStatus, _setSyncStatus] = useState<Record<string, boolean>>({});

  const handleSaveProblem = async (
    problemId: string,
    updatedProblem: ProblemDetails,
  ) => {
    try {
      setError(null);
      console.log("Saving problem:", problemId, updatedProblem);

      const result = await ProblemService.updateProblem(
        problemId,
        updatedProblem,
      );

      if (result.success) {
        console.log("Problem updated successfully");
        // Update the local state with the updated problem
        setProblems((prevProblems) =>
          prevProblems.map((p) =>
            p.problemId === problemId
              ? { ...p, ...updatedProblem, type: updatedProblem.type }
              : p,
          ),
        );
      } else {
        throw new Error(result.error || "Failed to update problem");
      }
    } catch (error) {
      console.error("Error updating problem:", error);
      setError("Failed to update problem. Please try again.");
    }
  };

  return (
    <div>
      <div className="bg-gray-100 p-4 mb-4 rounded-lg">
        <h2 className="text-2xl font-bold mb-2">
          Editing Challenge: {challengeRun.challenge.name}
        </h2>
        <p>
          <strong>Challenge Type:</strong> {challengeRun.challenge.type}
        </p>
      </div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          {error}
        </div>
      )}
      <DraggableProblemList
        problems={problems}
        setProblems={setProblems}
        handleSaveProblem={handleSaveProblem}
        handleLabelProblem={async () => {
          /* Implement if needed */
        }}
        syncStatus={syncStatus}
      />
    </div>
  );
};

export default ChallengeEditor;
