import type React from "react";
import LatexRenderer from "./LatexRenderer";

const Week1AimeSection: React.FC = () => {
  return (
    <>
      <p className="mb-6">
        On Friday February 7th, we held an AIME practice session where we
        reviewed{" "}
        <a
          href="https://www.grindolympiads.com/challenge/o1PupuseoA3avCsD5DVm"
          className="text-blue-600 hover:underline"
        >
          2025 AIME I Problems 1-3
        </a>
        . Below are the key insights from our discussion:
      </p>

      <div className="mb-6">
        <details className="mb-4">
          <summary className="cursor-pointer font-semibold">
            Problem 1 Solution
          </summary>
          <div className="pl-4 mt-2">
            <p>
              <LatexRenderer latex="$b + 7$ divides $9b + 7$, so given that we know $b+7$ divides $9b + 63$, it must also divide $(9b + 63) - (9b + 7)$ which is $56$. As a general strategy at all levels of competition, it is desirable to get the highest degree terms out of the numerator as we did here, so you can force the variable amount to divide into a constant number, such as 56 in this case." />
            </p>
            <p>
              <LatexRenderer latex="With the problem constraints requiring $b+7 \gt 16$ we know $b+7$ can only equal $28$ or $56$." />
            </p>
            <p>
              <LatexRenderer latex="Therefore, the possible values for $b$ are $21$ and $49$." />
            </p>
            <p>
              Final answer:{" "}
              <span className="border-2 border-black px-2">070</span>
            </p>
          </div>
        </details>

        <details className="mb-4">
          <summary className="cursor-pointer font-semibold">
            Problem 2 Solution
          </summary>
          <div className="pl-4 mt-2">
            <p>
              The heptagon is formed by shearing transformations of triangle
              ABC. If you divided it into infinitesimally thin horizontal
              strips, each strip is simply shifted left or right while
              preserving its width, allowing the triangle to be reconstituted
              into the heptagon without changing any areas. Alternately, you can
              observe that there are trapezoids with the same height, but the
              bases have been moved left or right while not having their lengths
              modified, which demonstrably proves that the areas of the
              trapezoids before and after transformation are not changed.
            </p>
            <p>
              Given that the triangle can be split up into 49 copies of the top
              section:
            </p>
            <ul className="list-disc pl-6">
              <li>The top portion has 1/49 of the triangle</li>
              <li>
                <LatexRenderer latex="The middle portion has area equal to 24 times the top portion (24 is $5^2 - 1^2$)" />
              </li>
              <li>
                <LatexRenderer latex="The bottom portion has area equal to 24 times the top portion (24 is $7^2 - 5^2$)" />
              </li>
            </ul>
            <p>
              Since the middle portion is given with area 288, the top portion
              must have area = 288/24 = 12, and the whole triangle must have
              area equal to 49 * 12 ={" "}
              <span className="border-2 border-black px-2">588</span>
            </p>
          </div>
        </details>

        <details className="mb-4">
          <summary className="cursor-pointer font-semibold">
            Problem 3 Solution
          </summary>
          <div className="pl-4 mt-2">
            <p>If one person picks strawberry:</p>
            <ul className="list-disc pl-6">
              <li>
                The breakdown of chocolate and vanilla is either 5 and 3, or 6
                and 2
              </li>
              <li>There are 9 choices of strawberry person</li>
              <li>
                <LatexRenderer latex="For each of the nine choices we can either have $C(9,5)$ or $C(9,6)$ picks for the other two groups" />
              </li>
              <li>
                <LatexRenderer latex="So there are $9 * (28 + 56) = 756$ ways for 1 person to have strawberry" />
              </li>
            </ul>
            <p>If two people have strawberry:</p>
            <ul className="list-disc pl-6">
              <li>
                There must be 4 and 3 in the other groups (and this is
                exhaustive of all cases)
              </li>
              <li>
                <LatexRenderer latex="We can pick strawberry people $C(9,2) = 36$ ways" />
              </li>
              <li>
                <LatexRenderer latex="We can divide 7 into 4 and 3 in $C(7,4) = 35$ ways" />
              </li>
              <li>
                <LatexRenderer latex="Thus $35 * 36 = 1260$ is the remaining number of combinations" />
              </li>
            </ul>
            <p>
              <LatexRenderer latex="The final answer is $1260 + 756$ reduced mod $1000$, so $2016$ mod $1000$ or " />
              <span className="border-2 border-black px-2">016</span>
            </p>
          </div>
        </details>
      </div>

      <p className="mb-6">
        The{" "}
        <a
          href="https://www.grindolympiads.com/challenge/h26sokkHXjaU1JYIIM8W"
          className="text-blue-600 hover:underline"
        >
          2002 Nats Sprint Practice
        </a>{" "}
        was administered in person to students at Kellogg on Friday. While we
        won't be doing this practice over Zoom, I encourage all students to work
        through it independently.
      </p>
    </>
  );
};

export default Week1AimeSection;
