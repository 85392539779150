import type { EnhancedChallengeRun } from "../components/ChallengeScoringSummary";
import type {
  ChallengeData,
  GradedChallenge,
  ProblemAnalysis,
  ProblemDetails,
} from "../types";
import { makeAPICall } from "./apiClient";

export interface FetchChallengesResponse {
  challenges: ChallengeData[];
}

export interface FetchGradedChallengesResponse {
  gradedChallenges: GradedChallenge[];
}

export interface SubmitChallengeResponse {
  score: number;
}

export interface FetchChallengeRunsResponse {
  challengeRuns: EnhancedChallengeRun[];
}

export interface GradeChallengeResponse {
  status: string;
}

/**
 * Fetch challenge runs within a date range
 * @param startDate Start date for the range
 * @param endDate End date for the range
 * @param limit Maximum number of runs to fetch
 * @returns A promise that resolves to the challenge runs response
 */
export async function fetchChallengeRuns(
  startDate: string,
  endDate: string,
  limit: number,
): Promise<FetchChallengeRunsResponse> {
  return makeAPICall<
    { startDate: string; endDate: string; limit: number },
    FetchChallengeRunsResponse
  >("fetchChallengeRuns", { startDate, endDate, limit });
}

/**
 * Grade a challenge run
 * @param challengeRunId The ID of the challenge run
 * @param problemAnalysis The analysis of each problem
 * @returns A promise that resolves to the grading response
 */
export async function gradeChallenge(
  challengeRunId: string,
  problemAnalysis: ProblemAnalysis[],
): Promise<GradeChallengeResponse> {
  return makeAPICall<
    { challengeRunId: string; problemAnalysis: ProblemAnalysis[] },
    GradeChallengeResponse
  >("gradeChallenge", { challengeRunId, problemAnalysis });
}

/**
 * Fetch all challenges
 * @returns A promise that resolves to the list of challenges
 */
export async function fetchChallenges(): Promise<FetchChallengesResponse> {
  return makeAPICall<void, FetchChallengesResponse>(
    "fetchChallenges",
    undefined,
  );
}

/**
 * Fetch graded challenges for a specific user
 * @param userId The ID of the user to fetch graded challenges for
 * @returns A promise that resolves to the list of graded challenges
 */
export async function fetchGradedChallenges(
  userId: string,
): Promise<FetchGradedChallengesResponse> {
  return makeAPICall<{ userId: string }, FetchGradedChallengesResponse>(
    "fetchGradedChallenges",
    { userId },
  );
}

/**
 * Submit a challenge response
 * @param challengeId The ID of the challenge
 * @param responses The user's responses to the challenge
 * @returns A promise that resolves to the score
 */
export async function submitChallengeResponse(
  challengeId: string,
  responses: { [key: string]: { answer: string; timeSpent: number } },
): Promise<SubmitChallengeResponse> {
  return makeAPICall<
    {
      challengeId: string;
      responses: { [key: string]: { answer: string; timeSpent: number } };
    },
    SubmitChallengeResponse
  >("submitChallengeResponse", { challengeId, responses });
}

// Problem-related operations have been moved to ProblemService
