import type { ChallengeRun, Exam } from "shared-types";
import { makeAPICall } from "../api/apiClient";

class ExamService {
  async getExam(examId: string): Promise<Exam> {
    const result = await makeAPICall<{ examId: string }, { exam: Exam }>(
      "getExam",
      { examId },
    );
    return result.exam;
  }

  async startChallenge(params: {
    examId?: string;
    challengeId?: string;
    challengeType?: string;
  }): Promise<ChallengeRun> {
    const result = await makeAPICall<
      typeof params,
      { challengeRun: ChallengeRun }
    >("beginChallenge", params);
    return result.challengeRun;
  }
}

export const examService = new ExamService();
