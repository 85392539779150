import type React from "react";
import { useState } from "react";
import LatexRenderer from "./LatexRenderer";

interface InlineEditableOptionsProps {
  options: Record<string, string>;
  correctAnswer: string;
  onOptionsChange: (options: Record<string, string>) => void;
  onCorrectAnswerChange: (correctAnswer: string) => void;
}

const InlineEditableOptions: React.FC<InlineEditableOptionsProps> = ({
  options,
  correctAnswer,
  onOptionsChange,
  onCorrectAnswerChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [optionsText, setOptionsText] = useState(
    Object.entries(options)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n"),
  );

  const handleOptionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOptionsText(e.target.value);
  };

  const handleCorrectAnswerChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    onCorrectAnswerChange(e.target.value);
  };

  const toggleEdit = () => {
    if (isEditing) {
      // Parse the options text when done editing
      const lines = optionsText.split("\n").filter((line) => line.trim());
      const newOptions: Record<string, string> = {};

      // Check if any line contains a colon (explicit key-value format)
      const hasExplicitKeys = lines.some((line) => line.includes(":"));

      if (hasExplicitKeys) {
        // Format: "A: value" or "A : value"
        for (const line of lines) {
          const match = line.match(/^([A-E])\s*:\s*(.+)$/);
          if (match) {
            const [, key, value] = match;
            newOptions[key] = value.trim();
          }
        }
      } else {
        // Format: just values, assign A, B, C, D, E in order
        const keys = ["A", "B", "C", "D", "E"];
        for (let index = 0; index < lines.length; index++) {
          if (index < keys.length) {
            newOptions[keys[index]] = lines[index].trim();
          }
        }
      }

      onOptionsChange(newOptions);
    }

    setIsEditing(!isEditing);
  };

  return (
    <div className="mb-4 border rounded p-3">
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-bold">Multiple Choice Options</h3>
        <button
          type="button"
          onClick={toggleEdit}
          className="text-blue-500 text-sm"
        >
          {isEditing ? "Done" : "Edit"}
        </button>
      </div>

      {isEditing ? (
        <div>
          <div className="mb-3">
            <label htmlFor="options" className="block font-bold mb-1">
              Options (A-E)
            </label>
            <textarea
              id="options"
              value={optionsText}
              onChange={handleOptionsChange}
              rows={5}
              className="w-full p-2 border rounded font-mono"
              // autoFocus removed for accessibility
              placeholder="A: Option text
B: Option text
C: Option text"
            />
            <p className="text-sm text-gray-500 mt-1">
              Enter one option per line, either with explicit keys (A: value) or
              just values in A, B, C, D, E order.
            </p>
          </div>

          <div>
            <label htmlFor="correctAnswer" className="block font-bold mb-1">
              Correct Answer
            </label>
            <select
              id="correctAnswer"
              value={correctAnswer}
              onChange={handleCorrectAnswerChange}
              className="w-full p-2 border rounded"
            >
              <option value="">Select correct answer</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
            </select>
          </div>
        </div>
      ) : (
        <div className="bg-gray-50 p-3 rounded">
          <div className="space-y-2">
            {Object.entries(options).map(([key, value]) => (
              <div
                key={key}
                className={`p-2 border rounded ${
                  key === correctAnswer ? "bg-green-100 border-green-500" : ""
                }`}
              >
                <span className="font-bold mr-2">{key}:</span>
                <LatexRenderer latex={`$${value}$`} />
              </div>
            ))}
          </div>
          {!correctAnswer && (
            <p className="text-red-500 mt-2">No correct answer set</p>
          )}
        </div>
      )}
    </div>
  );
};

export default InlineEditableOptions;
