import type { Point, Shape } from "./types";

export const drawShape = (
  ctx: CanvasRenderingContext2D,
  shape: Shape,
): void => {
  ctx.beginPath();
  ctx.strokeStyle = shape.color;
  ctx.lineWidth = shape.lineWidth;
  ctx.fillStyle = shape.fillColor || "#FFFFFF";

  if (
    shape.type === "rectangle" &&
    shape.startX !== undefined &&
    shape.startY !== undefined &&
    shape.width !== undefined &&
    shape.height !== undefined
  ) {
    ctx.rect(shape.startX, shape.startY, shape.width, shape.height);
  } else if (
    shape.type === "circle" &&
    shape.startX !== undefined &&
    shape.startY !== undefined &&
    shape.radius !== undefined
  ) {
    ctx.arc(shape.startX, shape.startY, shape.radius, 0, 2 * Math.PI);
  } else if (
    shape.type === "line" &&
    shape.startX !== undefined &&
    shape.startY !== undefined &&
    shape.endX !== undefined &&
    shape.endY !== undefined
  ) {
    ctx.moveTo(shape.startX, shape.startY);
    ctx.lineTo(shape.endX, shape.endY);

    // Draw connection points
    ctx.fillStyle = shape.color;
    ctx.beginPath();
    ctx.arc(shape.startX, shape.startY, 4, 0, 2 * Math.PI);
    ctx.arc(shape.endX, shape.endY, 4, 0, 2 * Math.PI);
    ctx.fill();
  } else if (
    shape.type === "pencil" &&
    shape.points &&
    shape.points.length > 0
  ) {
    ctx.moveTo(shape.points[0].x, shape.points[0].y);
    for (const point of shape.points) {
      ctx.lineTo(point.x, point.y);
    }
  } else if (
    shape.type === "text" &&
    shape.text !== undefined &&
    shape.x !== undefined &&
    shape.y !== undefined
  ) {
    ctx.font = "20px Arial";
    ctx.fillStyle = shape.color;
    ctx.fillText(shape.text, shape.x, shape.y);
  }

  if (shape.type !== "text" && shape.type !== "line" && shape.isFilled) {
    ctx.fill();
  }
  ctx.stroke();
};

export const drawTemporaryLine = (
  ctx: CanvasRenderingContext2D,
  startPos: Point,
  endPos: Point,
  color: string,
  lineWidth: number,
): void => {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.moveTo(startPos.x, startPos.y);
  ctx.lineTo(endPos.x, endPos.y);
  ctx.stroke();

  // Draw connection points
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(startPos.x, startPos.y, 4, 0, 2 * Math.PI);
  ctx.arc(endPos.x, endPos.y, 4, 0, 2 * Math.PI);
  ctx.fill();
};

export const findNearestPoint = (
  points: Point[],
  x: number,
  y: number,
  threshold: number,
): Point | null => {
  let nearest: Point | null = null;
  let minDistance = threshold;

  for (const point of points) {
    const distance = Math.sqrt((point.x - x) ** 2 + (point.y - y) ** 2);
    if (distance < minDistance) {
      minDistance = distance;
      nearest = point;
    }
  }

  return nearest;
};
