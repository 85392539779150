import type {
  LoginTokenResponse,
  TokenLoginResponse,
  UserProfileResponse,
} from "shared-types/src/types/auth";
import { auth } from "../firebase";
import type { UserProfile } from "../types";
import { makeAPICall } from "./apiClient";

/**
 * Get user profile
 * @returns A promise that resolves to the user's profile
 */
export async function getUserProfile(): Promise<UserProfile> {
  const response = await makeAPICall<void, UserProfileResponse>(
    "fetchUserProfile",
    undefined,
  );
  return response.user;
}

/**
 * Login user
 */
export async function login(): Promise<void> {
  return makeAPICall<void, void>("login", undefined);
}

/**
 * Logout user
 */
export async function logout(): Promise<void> {
  return makeAPICall<void, void>("logout", undefined);
}

/**
 * Get the current user's bearer token
 * @returns A promise that resolves to the bearer token or null
 */
export async function getBearerToken(): Promise<string | null> {
  try {
    if (!auth) {
      console.error("Firebase auth is not initialized");
      return null;
    }

    // Get the current Firebase user
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No user is currently logged in");
      return null;
    }

    // Get the ID token (bearer token) directly from Firebase
    const idToken = await currentUser.getIdToken(true);
    return idToken;
  } catch (error) {
    console.error("Error getting bearer token:", error);
    return null;
  }
}

/**
 * Generate a login token for the user
 * @returns A promise that resolves to the login token or null
 * @deprecated Use getBearerToken() instead for CLI authentication
 */
export async function generateLoginToken(): Promise<string | null> {
  const response = await makeAPICall<
    { generateToken: true },
    LoginTokenResponse
  >("generateLoginToken", { generateToken: true });
  return response.loginToken?.token || null;
}

/**
 * Login with a token
 * @param token The authentication token
 * @returns A promise that resolves to a boolean indicating success
 */
export async function loginWithToken(token: string): Promise<boolean> {
  try {
    const response = await makeAPICall<{ token: string }, TokenLoginResponse>(
      "loginWithToken",
      { token },
    );

    return response.success;
  } catch (error) {
    console.error("Error logging in with token:", error);
    return false;
  }
}
