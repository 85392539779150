import type React from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Exam } from "shared-types";
import { useAuth } from "../contexts/AuthContext";
import useChallenges from "../hooks/useChallenges";
import type { TestPresentationProps } from "../types";
import CategoryView from "./CategoryView";
import ExamTypeSection from "./ExamTypeSection";
import NonAMCView from "./NonAMCView";

type TabType = "AMC" | "ARML" | "Washington" | "Other";

const TestPresentation: React.FC<TestPresentationProps> = ({
  tests,
  isAdminMode,
  challenges,
}) => {
  const [activeTab, setActiveTab] = useState<TabType>("AMC");
  const _navigate = useNavigate();
  const { userProfile, viewAsUserProfile } = useAuth();
  const { gradedChallenges, isViewingAsOtherUser = false } = useChallenges();

  const groupedExams = useMemo(() => {
    const grouped: Record<string, Exam[]> = {};
    for (const exam of tests) {
      const groupName = exam.examType;
      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }
      grouped[groupName].push(exam);
    }
    return grouped;
  }, [tests]);

  const amcGroupOrder = ["AMC-8", "AMC-10", "AMC-12", "AIME"];
  const orderedAMCGroups = amcGroupOrder
    .filter((type) => groupedExams[type])
    .map((type) => ({
      type,
      exams: groupedExams[type],
    }));

  // Group non-AMC tests by category
  const categorizedTests = useMemo(() => {
    const nonAMC = tests.filter(
      (test) => !amcGroupOrder.includes(test.examType),
    );
    const armlTests = nonAMC.filter((test) =>
      ["ARML", "NYSML"].includes(test.competition),
    );

    return {
      arml: armlTests,
      washington: nonAMC.filter(
        (test) =>
          ["KPMT", "WAMO", "I2MC"].includes(test.examType) ||
          ["WAMO", "KPMT", "I2MC"].includes(test.competition),
      ),
      other: nonAMC.filter(
        (test) =>
          !["ARML", "NYSML"].includes(test.competition) &&
          !["KPMT", "WAMO", "I2MC"].includes(test.examType) &&
          !["WAMO", "KPMT", "I2MC"].includes(test.competition),
      ),
    };
  }, [tests]);

  const tabs: TabType[] = ["AMC", "ARML", "Washington", "Other"];

  return (
    <div className="p-4 bg-gray-50">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-4">
        {/* Header section */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Test Progress Dashboard</h1>
          {isViewingAsOtherUser && (
            <div className="text-sm text-gray-600">
              Viewing progress for {viewAsUserProfile?.name}
            </div>
          )}
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200 mb-4">
          <nav className="-mb-px flex space-x-4">
            {tabs.map((tab) => (
              <button
                type="button"
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`py-2 px-4 text-sm font-medium border-b-2 ${
                  activeTab === tab
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>

        {/* Content */}
        <div className="h-96 overflow-y-auto">
          {activeTab === "AMC" ? (
            // AMC View
            orderedAMCGroups.map(({ type, exams }) => (
              <ExamTypeSection
                key={type}
                type={type}
                exams={exams}
                isAdminMode={isAdminMode}
                challenges={challenges}
                gradedChallenges={gradedChallenges}
                viewAsUserProfile={viewAsUserProfile}
                userProfile={userProfile}
                isViewingAsOtherUser={isViewingAsOtherUser}
              />
            ))
          ) : // Non-AMC Views
          activeTab === "ARML" ? (
            <CategoryView
              tests={categorizedTests.arml}
              isAdminMode={isAdminMode}
              isViewingAsOtherUser={isViewingAsOtherUser}
            />
          ) : (
            <NonAMCView
              nonAMCTests={
                activeTab === "Washington"
                  ? categorizedTests.washington
                  : categorizedTests.other
              }
              isAdminMode={isAdminMode}
              isViewingAsOtherUser={isViewingAsOtherUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestPresentation;
