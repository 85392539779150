import type { PracticeSession } from "shared-types";
import { makeAPICall } from "../api/apiClient";

// Mock data - would be replaced with API calls
const mockPracticeSessions: PracticeSession[] = [
  {
    id: "week4-friday",
    day: "Friday",
    title: "Week 4 Friday practice",
    focus: "2014 NATS",
    startTime: "2025-03-07T17:00:00",
    zoomLink:
      "https://zoom.us/j/87328775678?pwd=7BGZ1EgZkrWFSlsuigwVUYvnDSWMxi.1",
    problemSets: [
      {
        title: "2014 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/zSJR2LuykNRz4qAKHMal",
      },
      {
        title: "2014 Nats Target Challenge 1-2",
        time: "5:45pm - 6:15pm",
        url: "/challenge/GUvcYJFJEUadfFgNQNzF",
      },
      {
        title: "2014 Nats Target Challenge 3-4",
        time: "6:20pm - 6:50pm",
        url: "/challenge/HbeTZUByc5DAy2RtaBhB",
      },
      {
        title: "2014 Nats Target Challenge 5-6",
        time: "6:55pm - 7:25pm",
        url: "/challenge/YV98XQchbDws008oqYYc",
      },
      {
        title: "2014 Nats Target Challenge 7-8",
        time: "7:30pm - 8:00pm",
        url: "/challenge/bAdeXGGeXkm91mpexStl",
      },
    ],
  },
  {
    id: "week5-monday",
    day: "Monday",
    title: "Week 5 Monday practice",
    focus: "2015 NATS",
    startTime: "2025-03-10T17:00:00",
    zoomLink:
      "https://zoom.us/j/87328775678?pwd=7BGZ1EgZkrWFSlsuigwVUYvnDSWMxi.1",
    problemSets: [
      {
        title: "2015 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/1L4LKLIH2M2BqxBQsvSm",
      },
      {
        title: "2015 Nats Target Challenge 1-2",
        time: "5:45pm - 6:15pm",
        url: "/challenge/Jz7BMBw6Rl7jjqGw3smp",
      },
      {
        title: "2015 Nats Target Challenge 3-4",
        time: "6:20pm - 6:50pm",
        url: "/challenge/wxl0ml1DmAkD7BzCBKuF",
      },
      {
        title: "2015 Nats Target Challenge 5-6",
        time: "6:55pm - 7:25pm",
        url: "/challenge/5CDJKqhkQ7mDIIw5Usvo",
      },
      {
        title: "2015 Nats Target Challenge 7-8",
        time: "7:30pm - 8:00pm",
        url: "/challenge/QikZ2rBkyyCDBq1xfsiz",
      },
    ],
  },
  {
    id: "week5-wednesday",
    day: "Wednesday",
    title: "Week 5 Wednesday practice",
    focus: "2016 NATS",
    startTime: "2025-03-12T17:00:00",
    zoomLink:
      "https://zoom.us/j/87328775678?pwd=7BGZ1EgZkrWFSlsuigwVUYvnDSWMxi.1",
    problemSets: [
      {
        title: "2016 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/nNLhkxzIZhk5YmVcwoWW",
      },
      {
        title: "2016 Nats Target Challenge 1-2",
        time: "5:45pm - 6:15pm",
        url: "/challenge/ZjqK4QGqsyVtshVoRi3H",
      },
      {
        title: "2016 Nats Target Challenge 3-4",
        time: "6:20pm - 6:50pm",
        url: "/challenge/pPyKiZlCU2QhGOXIM7HD",
      },
      {
        title: "2016 Nats Target Challenge 5-6",
        time: "6:55pm - 7:25pm",
        url: "/challenge/VP7YM8bUPzpMQZiyvRbM",
      },
      {
        title: "2016 Nats Target Challenge 7-8",
        time: "7:30pm - 8:00pm",
        url: "/challenge/jgp2LYYFafaynU7dYt9Z",
      },
    ],
  },
  {
    id: "week5-friday",
    day: "Friday",
    title: "Week 5 Friday practice",
    focus: "2017 NATS",
    startTime: "2025-03-14T17:00:00",
    zoomLink:
      "https://zoom.us/j/87328775678?pwd=7BGZ1EgZkrWFSlsuigwVUYvnDSWMxi.1",
    problemSets: [
      {
        title: "2017 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/SrxLUDFMgcJGNL6Xa7ud",
      },
      {
        title: "2017 Nats Target Challenge 1-2",
        time: "5:45pm - 6:15pm",
        url: "/challenge/nVMZ8pUAkrAB7rBcTHXp",
      },
      {
        title: "2017 Nats Target Challenge 3-4",
        time: "6:20pm - 6:50pm",
        url: "/challenge/K00i0ItAQ0wR8AIMH4oh",
      },
      {
        title: "2017 Nats Target Challenge 5-6",
        time: "6:55pm - 7:25pm",
        url: "/challenge/TogJX7I90LnL2RpxXfGe",
      },
      {
        title: "2017 Nats Target Challenge 7-8",
        time: "7:30pm - 8:00pm",
        url: "/challenge/9oqSASXKDu46pXsB1qgm",
      },
    ],
  },
  {
    id: "week6-monday",
    day: "Monday",
    title: "Week 6 Monday practice",
    focus: "2018 NATS",
    startTime: "2025-03-17T17:00:00",
    zoomLink:
      "https://zoom.us/j/87328775678?pwd=7BGZ1EgZkrWFSlsuigwVUYvnDSWMxi.1",
    problemSets: [
      {
        title: "2018 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/7AlnG5h4rwcgI1lTvWHX",
      },
      {
        title: "2018 Nats Target Challenge 1-2",
        time: "5:45pm - 6:15pm",
        url: "/challenge/WZM9gMMumg4NmrqOP02y",
      },
      {
        title: "2018 Nats Target Challenge 3-4",
        time: "6:20pm - 6:50pm",
        url: "/challenge/gqKFQGVTlRR3kcvShC4w",
      },
      {
        title: "2018 Nats Target Challenge 5-6",
        time: "6:55pm - 7:25pm",
        url: "/challenge/hkMhqy2LecCj3LVX3G2A",
      },
      {
        title: "2018 Nats Target Challenge 7-8",
        time: "7:30pm - 8:00pm",
        url: "/challenge/e5HfKtfCvKWpfYaz9bbX",
      },
    ],
  },
  {
    id: "week6-wednesday",
    day: "Wednesday",
    title: "Week 6 Wednesday practice",
    focus: "2019 NATS",
    startTime: "2025-03-19T17:00:00",
    zoomLink:
      "https://us06web.zoom.us/j/87279194708?pwd=zCQXMjDN1O6KNF3p8MlXxppoqolozO.1",
    problemSets: [
      {
        title: "2019 Nats Sprint Practice",
        time: "5:00pm - 5:40pm",
        url: "/challenge/Krex13J5sjZipQkgBM0v",
      },
      {
        title: "2019 Nats Target Practice 1-2",
        time: "6:15pm - 6:30pm",
        url: "/challenge/Eb7WQVQJL5E91DWELtSm",
      },
      {
        title: "2019 Nats Target Practice 3-4",
        time: "6:30pm - 6:45pm",
        url: "/challenge/MyGIzzkY5FDGooL2zBkW",
      },
      {
        title: "2019 Nats Target Practice 5-6",
        time: "6:45pm - 7:00pm",
        url: "/challenge/6PJP1z8I14XdIdWJKpVO",
      },
      {
        title: "2019 Nats Target Practice 7-8",
        time: "7:00pm - 7:15pm",
        url: "/challenge/SLKQQpUWeVzMf9K3XQxo",
      },
      {
        title: "Countdown Races",
        time: "7:15pm - 7:45pm",
      },
    ],
  },
];

class PracticeService {
  async getPracticeSessions(): Promise<PracticeSession[]> {
    // In a real implementation this would be an API call
    // Example:
    // const result = await makeAPICall<void, { sessions: PracticeSession[] }>(
    //   "getPracticeSessions",
    //   {}
    // );
    // return result.sessions;

    // For now return mock data
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockPracticeSessions), 500);
    });
  }

  async getZoomLink(sessionId: string): Promise<string> {
    // In a real implementation this would be an API call
    // Example:
    // const result = await makeAPICall<{ sessionId: string }, { zoomLink: string }>(
    //   "getZoomLink",
    //   { sessionId }
    // );
    // return result.zoomLink;

    // For now return mock data
    return new Promise((resolve) => {
      setTimeout(
        () => resolve(`https://zoom.us/j/updated-link-${sessionId}`),
        1500,
      );
    });
  }

  async markSessionComplete(
    sessionId: string,
    isComplete: boolean,
  ): Promise<void> {
    // In a real implementation this would be an API call
    // Example:
    // await makeAPICall<{ sessionId: string, isComplete: boolean }, void>(
    //   "markSessionComplete",
    //   { sessionId, isComplete }
    // );

    // For now just log the action
    console.log(
      `Marking session ${sessionId} as ${isComplete ? "complete" : "incomplete"}`,
    );
    return Promise.resolve();
  }
}

export const practiceService = new PracticeService();
