import type { BlogPost } from "../types";

export const staticBlogPosts: Record<string, BlogPost[]> = {
  Updates: [
    {
      id: "placeholder-completion-dec-2024",
      date: "2024-12-12",
      title: "Problems .. so many problems",
      author: "Atlas",
      content: `(Quick note from John - Welcome Atlas to the blog! Atlas is training for AMC-8 and working on visual components for the site with the assistance of the claude artifacts generator. stay tuned for his whiteboard and graphing calculator features!)

---

Great news everyone! Remember that [pesky placeholder issue](/blog/placeholders-nov-2024) we've been tracking? Well, I'm thrilled to announce that as of today, we've officially cleared all placeholder content from our system! 🎉

The placeholders were disrupting my ability to grind AMC-8 so we spent a few days scrubbing out all of the known placeholder issues. We used two different approaches with Claude:

For AMC-8/10/12 problems with their A-E options, we used Claude's web interface with the $20/month subscription. Since these were 25-question tests with multiple choice options, we worked within the daily rate limits, feeding in a few tests each day to generate the JSON files.

For AIME problems, since there were only 15 per test and no multiple choice options, we used Claude's API to directly generate the files. The API costs were minimal given the smaller number of problems and simpler format.

In both cases, we fed the JSON into a Python script that would show us each problem one at a time. This let us do a quick eyeball test to make sure everything looked right before it went into the database.`,
    },
    {
      id: "kpmt-i2mc-nov-2024",
      date: "2024-11-19",
      title: "Adding KPMT and I2MC",
      content: `We're excited to have buy-in from the amazing students from Newport High School and Interlake High School to host the problems they wrote for past installments of the [Knights of Pi Math Tournament (KPMT)](https://newportmathclub.com/kpmt.html) and the Interlake Invitational Math Contest (I2MC). These problems should be posted shortly, as well as an update for problem submission (the short answer format is not utilized by the current challenges on our site where the answers are all A-E or 000-999). This is also a good time to thank Evan Chen for recently authorizing us to post the past problems of the NIMO (National Internet Math Olympiad).

For those interested, KPMT's next contest is scheduled for December 14th, with registration closing on November 30th - click the link above if you are a PNW middle school student who hasn't been registered to compete yet.

On a more general note, it is part of the GrindOlympiads mission to move toward "open sourcing" the problems from as many competitions as possible, creating a framework where others will be able to utilize the problems in good faith without having to seek out all the copyright-holding entities for permission. Stay tuned for updates on the progress of this endeavor!`,
    },
    {
      id: "shipping-stability-nov-2024",
      date: "2024-11-14",
      title: "Shipping Stability",
      author: "John",
      content: `Instead of flashy features, or even big bug fixes, sometimes the big win is just to get features to be a little bit more reliable.

With more and more Shadow AIME challenges ([1](https://www.grindolympiads.com/challenge/PXNzNd1WAquj1OaRRsw6), [2](https://www.grindolympiads.com/challenge/4oVPZUdCJrxVUloMnU9f), [3](https://www.grindolympiads.com/challenge/wPPDnsw2YymxzDfmGop1)) being put up this week in the aftermath of the AMC-10B, I've become painfully aware of reliability issues such as (1) actions not recording, (2) problems not advancing after answers are submitted, (3) [timer issues that are already documented in the roadmap section](/blog/timer-issues), but which manifest in console error messages. Some of these issues traced back to the way we were storing Challenge Runs in the database. These are documents in Firestore that belong to the "challengeRuns" collection. While it's not strictly necessary for these documents to map "id" to a string (since documents themselves know their id), it is the case that many of these documents were being stored with id set to an empty string "". By simply tweaking the backend to ensure that the field is always stored with the correct value, we were able to get a drastically improved experience across the tests. Along the way we did some of the work to get the timers improved -- they are still a work in progress, but incrementally better for what it's worth.

Would it be better to get rid of the id field and never refer to it in the code? Yeah it would probably be better. But it's not the most pressing issue that will help kids prepare for the AMC-8, the upcoming AIMEs, Mathcounts, and potentially the USAJMO. So we'll keep trying to facilitate preparation for those events as best we can, and maybe fix the ids thing on a rainy day.`,
    },
    {
      id: "chasing-aime-nov-2024",
      date: "2024-11-13",
      title: "Chasing AIME",
      author: "John",
      content: `We have downloaded most of the AIME historical content and started challenge support for AIMEs. I am not an advocate of the blocks-of-5 paradigm that I created for AMC 8/10/12 when it comes to the AIMEs. Rather you should expect to see "Target Practice" motivated by ARML and the Mathcounts Target round. Basically instead of 10 problems of roughly equal difficulty, I split adjacent AIME exams into 15 pairings of problem X and problem X+7.5 from the other exam (so 1a with 8b, 1b with 9a, 2a with 9b, 2b with 9a, you get it...)

For my kids who are just seeing AIME problems for the first time I think 2 problems per day is reasonable and leaves room for coaching (it would take about a year of daily practice to get through all the problems at this pace). For students who are impatient to grind through the AIME in the next couple months, we'll see if it has any practical value...

If you want to try out the format before it's fully released, there is a "shadow target practice" challenge available at https://www.grindolympiads.com/challenge/PXNzNd1WAquj1OaRRsw6`,
    },
    {
      id: "amc-survey-nov-2024",
      date: "2024-11-11",
      title: "HEY YO... Survey Time",
      author: "John",
      content: `With AMC-10A scores released the past couple days, I'm getting a sense that that math class you sit in 5 days a week in school might impact your score more than you think it does. To test this hypothesis I have put up a survey at grindolympiads.com/survey.

If you took the AMC-10A this year, fill it out! I want to know:
- What you scored
- How happy you were with your score
- What math class you're in
- What math class you think you should be in

Maybe I'm wrong, and preparation still matters more than what class they've put you in. Let's find out what the numbers say.`,
    },
    {
      id: "latex-update-nov-2024",
      date: "2024-11-09",
      title: "Aw snap! We fixed it",
      content: `Well, we finally got rid of the crashes. I knew that it was probably caused by the mathjax library that was rendering LaTeX, but I didn't have a concrete plan for attacking it.

Finally this weekend I decided to switch from using the better-react-mathjax package to using react-latex-next. There were some attempts in the middle to use mathlive, but I gave up on that.

So far things are looking good. I'll experiment with trying out some other LaTeX notations (e.g. matrices etc) and see if we have any new capabilities. In the meantime this is a big win for the test taking experience.

Happy Grinding!

-John`,
    },
  ],
  Roadmap: [
    {
      id: "pinned-roadmap-note",
      date: "2024-11-11",
      title: "[PINNED] About the Roadmap Section",
      content: `This section of the blog is a discussion of issues on the site that have known deficiencies that I am working on fixing. It's not entirely clear how I will handle these blog entries once the issues themselves have been fixed.

I want transparency in this section so users will understand the direction that the site is headed and the progression of features as they gradually transition from broken to functional.

-John`,
      pinned: true,
    },
    {
      id: "show-answers-nov-2024",
      date: "2024-11-14",
      title: "What was the right answer?",
      content: `Atlas keeps prodding me to have the site show you the right answer after you do a challenge. He is obviously right - this is an important feature - and hopefully it will not be long before students can see the right answer and see their progress on the challenges. I will also be rearchitecting the challenges page soon to transpose the matrix of challenges so it is more obvious that the intended path is for students to do all the first ten challenges year by year, then do the 11-15 challenges, etc. Also students should see little badges / star ratings indicating which ones they have done. We actually had this feature a few weeks ago but it was inadvertently broken while I was modifying the interface to make sure that I as an admin was able to see these badges on behalf of other users. Oops!

-John`,
    },
    {
      id: "placeholders-nov-2024",
      date: "2024-11-12",
      title: "4,906 placeholders",
      completedDate: "2024-12-12",
      content: `As you know if you have navigated through the AMC challenges on this site, many of the exams are unfinished. I dumped the Firestore database to json and ran this command: "cat firestore_dump.json | grep -c placeholder" and at latest count it told me that there are 4,906 placeholder strings in the database, which in a nutshell means that if you add up all the options (A-E for multiple choice problems) and problem statements that are not actually filled in, you get 4,906.

This isn't an immediate fix, but it is one of the many cases where LLMs (Claude and GPT in this case) are extremely pragmatic tools for the job. There is the option of paying x amount of dollars to just pass all of the documents up to your favorite LLM with API calls and having it dispatch function calls to overwrite the problems in the database. This is not my preferred approach (although it may be demonstrably the best approach). What I prefer is to have the consumer interface (what many of you think of as ChatGPT, or the Claude web interface, which is the one I will probably use) populated with other good examples of output files and then I feed tests in one at a time and ask it to generate new files. Then I have a script that I run against that file and it asks me one by one "do you want to accept these changes for 2011 problem 6" etc and I can see exactly what it is going to do. It is a bit more tedious and manual, and yet it is also very satisfying to watch the changes go in one by one and magically render beautifully on the site.	

So there it is, heading into today's AMC-10B, the number is 4,906. Stay tuned, and Happy Grinding!

Update from the future (12/07/2024) -- 3,265. Plus there is a lot of new AIME content that wasn't part of the earlier calculation.

-John

Exercise for the reader: write an interesting problem that hinges on the following
* Factorize(2024)= 2x2x2x11x23
* Factorize(4906)= 2x11x223`,
    },
    {
      id: "timer-issues",
      date: "2024-11-10",
      title: "Broken timers",
      author: "John",
      content: `I need to fix the timers on the tests. There is supposed to be one timer ticking at all times, either indicating how fast you can speed run the question you're on, or how long it takes you to check your answer, or how long you have paused in the middle of the test. Right now the timers are coded very poorly and even though this information is available to me after you take the test, you are not able to see it during your work.`,
    },
    {
      id: "latex-crashes",
      date: "2024-10-27",
      title: "Known Issue: Tests Crashing",
      completedDate: "2024-11-09",
      author: "John",
      content: `[FIXED - See [update from Nov 9th](/blog/latex-update-nov-2024)]

Having a problem with the browser crashing after you've looked at a problem for a while during a challenge or test. I'm pretty sure this is caused by the MathJax library we're using to render LaTeX equations. Hard to debug the issue because once the browser crashes, the console goes away.`,
    },
  ],
};

export const findPostByPath = (
  path: string,
): { post: BlogPost | null; section: string | null } => {
  const postId = path.split("/").pop();

  for (const [section, posts] of Object.entries(staticBlogPosts)) {
    const post = posts.find((p) => p.id === postId);
    if (post) {
      return { post, section };
    }
  }

  return { post: null, section: null };
};
