import type React from "react";
import { useNavigate } from "react-router-dom";
import type { Exam } from "shared-types";
import ARMLProblemSetList from "./ARMLProblemSetList";

interface CategoryViewProps {
  tests: Exam[];
  isAdminMode: boolean;
  isViewingAsOtherUser: boolean;
}

interface ExamCategory {
  title: string;
  filter: (exam: Exam) => boolean;
}

const CategoryView: React.FC<CategoryViewProps> = ({
  tests,
  isAdminMode,
  isViewingAsOtherUser,
}) => {
  const navigate = useNavigate();

  const categories: ExamCategory[] = [
    {
      title: "AMC",
      filter: (exam) =>
        ["AMC-8", "AMC-10", "AMC-12", "AIME"].includes(exam.examType),
    },
    {
      title: "ARML",
      filter: (exam) => ["ARML", "NYSML"].includes(exam.competition),
    },
    {
      title: "Washington",
      filter: (exam) =>
        ["KPMT", "WAMO", "I2MC"].includes(exam.examType) ||
        exam.competition === "WAMO" ||
        exam.competition === "KPMT" ||
        exam.competition === "I2MC",
    },
    {
      title: "Other",
      filter: (exam) =>
        !["AMC-8", "AMC-10", "AMC-12", "AIME", "KPMT", "WAMO", "I2MC"].includes(
          exam.examType,
        ) &&
        !["ARML", "NYSML", "WAMO", "KPMT", "I2MC"].includes(exam.competition),
    },
  ];

  return (
    <div className="space-y-6">
      {categories.map((category) => {
        const categoryExams = tests
          .filter(category.filter)
          .sort((a, b) => Number(a.year) - Number(b.year));

        if (categoryExams.length === 0) return null;

        return (
          <div key={category.title} className="space-y-3">
            <h2 className="text-lg font-semibold border-b pb-2">
              {category.title}
            </h2>
            {category.title === "ARML" ? (
              <ARMLProblemSetList
                problemSets={categoryExams}
                isViewingAsOtherUser={isViewingAsOtherUser}
              />
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                {categoryExams.map((test) => (
                  <div
                    key={test.id}
                    className="bg-white rounded-md shadow-sm p-3 flex flex-col justify-between border hover:bg-gray-50 transition-colors"
                  >
                    <div>
                      <h3 className="font-semibold text-sm">
                        {test.competition}
                      </h3>
                      <p className="text-gray-500 text-xs">
                        {`${test.year} - ${test.name}`}
                      </p>
                    </div>
                    {isAdminMode ? (
                      <button
                        type="button"
                        onClick={() => navigate(`/exam/${test.id}`)}
                        className="mt-2 bg-blue-500 text-white py-1 px-2 rounded text-xs hover:bg-blue-600 transition-colors"
                        aria-label={`Edit ${test.name}`}
                      >
                        Edit Test
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          !isViewingAsOtherUser && navigate(`/exam/${test.id}`)
                        }
                        className={`mt-2 py-1 px-2 rounded text-xs transition-colors ${
                          isViewingAsOtherUser
                            ? "bg-gray-300 cursor-not-allowed"
                            : "bg-blue-500 hover:bg-blue-600 text-white"
                        }`}
                        disabled={isViewingAsOtherUser}
                      >
                        Take Test
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CategoryView;
