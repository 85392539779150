import type { Exam } from "shared-types";
import type { ExamUpdateData, ProblemDetails } from "shared-types";
import { makeAPICall } from "./apiClient";

export interface SubmitAnswerRequest {
  examId: string;
  problemId: string;
  answer: string;
  timestamp: number;
}

export interface CreateProblemResponse {
  success: boolean;
  problemId?: string;
}

export interface LabelProblemResponse {
  success: boolean;
}

export interface UpdateExamResponse {
  success: boolean;
}

export interface CreateExamRequest {
  name: string;
  competition: string;
  year: string;
  examType: Exam["examType"];
}

export interface CreateExamResponse {
  examId: string;
}

// Problem-related operations have been moved to ProblemService

/**
 * Create a new exam
 * @param request The exam creation request
 * @returns A promise that resolves to the creation response
 */
export async function createExam(
  request: CreateExamRequest,
): Promise<CreateExamResponse> {
  return makeAPICall<CreateExamRequest, CreateExamResponse>(
    "createExam",
    request,
  );
}

/**
 * Create a new problem
 * @param problem The problem details
 * @returns A promise that resolves to the creation response
 */
export async function createProblem(
  problem: ProblemDetails,
): Promise<CreateProblemResponse> {
  return makeAPICall<{ problem: ProblemDetails }, CreateProblemResponse>(
    "createProblem",
    { problem },
  );
}

/**
 * Label a problem for an exam
 * @param examId The ID of the exam
 * @param problemId The ID of the problem
 * @param label The label to assign
 * @returns A promise that resolves to the labeling response
 */
export async function labelProblemForExam(
  examId: string,
  problemId: string,
  label: string,
): Promise<LabelProblemResponse> {
  return makeAPICall<
    { examId: string; problemId: string; label: string },
    LabelProblemResponse
  >("labelProblemForExam", { examId, problemId, label });
}

/**
 * Get an exam by ID
 * @param examId The ID of the exam to fetch
 * @returns A promise that resolves to the exam data
 */
export async function getExam(examId: string): Promise<Exam> {
  return makeAPICall<{ examId: string }, Exam>("getExam", { examId });
}

/**
 * Submit an answer for a problem
 * @param request The answer submission request
 * @returns A promise that resolves when submission is complete
 */
export async function submitAnswer(
  request: SubmitAnswerRequest,
): Promise<void> {
  return makeAPICall<SubmitAnswerRequest, void>("submitAnswer", request);
}

/**
 * Update an exam
 * @param examId The ID of the exam to update
 * @param examType The type of exam
 * @param problemsToConvert Array of problem IDs to convert
 * @returns A promise that resolves to the update response
 */
export async function updateExam(
  examId: string,
  examType: Exam["examType"],
  problemsToConvert: string[],
): Promise<UpdateExamResponse> {
  return makeAPICall<ExamUpdateData, UpdateExamResponse>("updateExam", {
    examId,
    examType,
    updates: {}, // Empty updates object to satisfy the interface
    problemsToConvert,
  });
}
