import type React from "react";
import LatexRenderer from "./LatexRenderer";

const Week1GeneralSection: React.FC = () => {
  return (
    <>
      <p className="mb-6">
        Greetings parents and coaches. We held our first practice kickoff on
        Monday February 3rd and were delighted to have students from Einstein
        Middle School join our pre-established practice groups. We look forward
        to having many of your other students join us in days to come.
      </p>

      <p className="mb-4">Monday February 3rd Session:</p>
      <ul className="mb-6">
        <li>
          5:00-5:40pm{" "}
          <a
            href="https://www.grindolympiads.com/challenge/4QZT9OCKKHbuGFWAljhD"
            className="text-blue-600 hover:underline"
          >
            2001 Nats Sprint Practice
          </a>
        </li>
        <li>
          6:15 - 7:00pm
          <ul className="ml-6 mt-2">
            <li>
              <a
                href="https://www.grindolympiads.com/challenge/HcAGqErs7XUDtHjpAlLp"
                className="text-blue-600 hover:underline"
              >
                2001 Nats Target Practice 1-2
              </a>
            </li>
            <li>
              <a
                href="https://www.grindolympiads.com/challenge/wwLOtsW0sN8l1ccMi0c7"
                className="text-blue-600 hover:underline"
              >
                2001 Nats Target Practice 3-4
              </a>
            </li>
            <li>
              <a
                href="https://www.grindolympiads.com/challenge/3qTGhVQqRDxb7rXHDPy5"
                className="text-blue-600 hover:underline"
              >
                2001 Nats Target Practice 5-6
              </a>
            </li>
            <li>
              <a
                href="https://www.grindolympiads.com/challenge/fCFw2gtxwF1TH8AeoIN0"
                className="text-blue-600 hover:underline"
              >
                2001 Nats Target Practice 7-8
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <p className="mb-6">
        One of the problems that led to a deeper theory discussion was question
        4. This problem discusses the recursion satisfying{" "}
        <LatexRenderer latex="$a_{n+2} + 2a_{n+1} - 3a_n = 0$" />. Because of
        the starting values -1 and 3, it happened to work out that each value is
        simply <LatexRenderer latex="$(-3)^{n-1}$" /> times (-1). However this
        didn't have to be the case.
      </p>

      <p className="mb-6">
        I discussed the characteristic polynomial{" "}
        <LatexRenderer latex="$x^2 + 2x - 3$" /> which has roots 1 and -3. With
        a degree two recursion and two distinct roots of the polynomial, we can
        always write the closed form of the sequence as{" "}
        <LatexRenderer latex="$a\cdot(-3)^n + b\cdot(1)^n$" />.
      </p>

      <p className="mb-6">
        We then transitioned with our advanced group to review 1984 ARML. Note:
        while labeled as ARML, the first two problems (
        <a
          href="https://www.grindolympiads.com/challenge/KS4PiZARcBzRqw3IldNr"
          className="text-blue-600 hover:underline"
        >
          Target Practice 1-2
        </a>
        ) are actually from 1985 NYSML. We also worked on{" "}
        <a
          href="https://www.grindolympiads.com/challenge/9Kf8qmG6cWhMiDEn7pQs"
          className="text-blue-600 hover:underline"
        >
          Target Practice 3-4
        </a>
        ,{" "}
        <a
          href="https://www.grindolympiads.com/challenge/5mtt1KRslmHB2el8j1pW"
          className="text-blue-600 hover:underline"
        >
          Target Practice 5-6
        </a>
        , and{" "}
        <a
          href="https://www.grindolympiads.com/challenge/XfUoUqg56F7asszcMvRJ"
          className="text-blue-600 hover:underline"
        >
          Target Practice 7-8
        </a>{" "}
        from the 84 ARML contest.
      </p>

      <p className="mb-4">Wednesday February 5th Session:</p>
      <p className="mb-6">
        No practice was held this week, but we plan to evaluate interest in
        Wednesday sessions for future weeks.
      </p>

      <p className="mb-4">Friday February 7th Session:</p>
      <p className="mb-6">
        The{" "}
        <a
          href="https://www.grindolympiads.com/challenge/h26sokkHXjaU1JYIIM8W"
          className="text-blue-600 hover:underline"
        >
          2002 Nats Sprint Practice
        </a>{" "}
        was administered in person to students at Kellogg. While we won't be
        doing this practice over Zoom, I encourage all students to work through
        it independently.
      </p>
    </>
  );
};

export default Week1GeneralSection;
