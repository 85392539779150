import type { ImageData } from "shared-types";

export interface BaseProblemDetails {
  statement: string;
  image?: ImageData;
  diagrams?: string[];
}

export interface AMCProblemDetails extends BaseProblemDetails {
  type: "multiple_choice";
  options: Record<string, string>;
  correctAnswer: string;
}

export interface AIMEProblemDetails extends BaseProblemDetails {
  type: "aime"; // Specific type for AIME's 000-999 format
  correctAnswer: string;
}

export interface ShortAnswerProblemDetails extends BaseProblemDetails {
  type: "short_answer";
  correctAnswer: string;
}

export interface OlympiadProblemDetails extends BaseProblemDetails {
  type: "essay";
  solutionGuidelines?: string;
}

export type ProblemDetails =
  | AMCProblemDetails
  | AIMEProblemDetails
  | ShortAnswerProblemDetails
  | OlympiadProblemDetails;

export const isAMCProblem = (
  problem: ProblemDetails,
): problem is AMCProblemDetails => problem.type === "multiple_choice";

export const isAIMEProblem = (
  problem: ProblemDetails,
): problem is AIMEProblemDetails => problem.type === "aime";

export const isShortAnswerProblem = (
  problem: ProblemDetails,
): problem is ShortAnswerProblemDetails => problem.type === "short_answer";

export const isOlympiadProblem = (
  problem: ProblemDetails,
): problem is OlympiadProblemDetails => problem.type === "essay";

export type ExamType =
  | "AMC-8"
  | "AMC-10"
  | "AMC-12"
  | "AIME"
  | "ARML"
  | "Individual"
  | "Team"
  | "Power"
  | "Relay"
  | "Olympiad"
  | "MATHCOUNTS"
  | "other";

export type ActionType =
  | "createChallengeRun"
  | "guessAnswer"
  | "loadSavedState"
  | "navigateAway"
  | "openProblem"
  | "openTest"
  | "skipProblem"
  | "submitAnswer"
  | "viewAllProblems"
  | "viewSingleProblem";

export interface Action {
  type: ActionType;
  timestamp: string;
  challengeRunId?: string;
  problemLabel?: string;
  userId?: string;
  userName?: string;
  data?: Record<string, unknown>;
}

export interface EnhancedAction extends Action {
  userName?: string;
  challengeName?: string;
  examName?: string;
}

export type ProblemStatus =
  | "unattempted"
  | "answered"
  | "confirmed"
  | "skipped"
  | "guessed"
  | "conflicting";

export interface ProblemReference {
  examId: string;
  problemId: string;
  label: string;
}
