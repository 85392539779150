import type React from "react";
import { usePractice } from "../hooks/usePractice";
import PracticeDetails from "./PracticeDetails";
import PracticeNavigation from "./PracticeNavigation";
import ProblemSetList from "./ProblemSetList";
import SessionNotes from "./SessionNotes";

/**
 * Practice component displays practice sessions with navigation and problem sets.
 *
 * This component follows the container/presentational pattern:
 * - It uses the usePractice hook for state management and business logic
 * - It composes smaller, focused UI components for rendering
 */
const Practice: React.FC = () => {
  // Use initialIndex 5 to default to 2019 NATS
  const {
    practiceSessions,
    currentSessionIndex,
    currentSession,
    isLoading,
    error,
    showNotes,
    loadingZoom,
    countdown,
    goToPrevious,
    goToNext,
    sessionHasEnded,
  } = usePractice(5);

  if (isLoading) {
    return <div className="text-center p-8">Loading practice sessions...</div>;
  }

  if (error) {
    return <div className="text-center p-8 text-red-500">{error}</div>;
  }

  if (!currentSession) {
    return <div className="text-center p-8">No practice sessions found.</div>;
  }

  // Get previous and next sessions for navigation
  const previousSession =
    currentSessionIndex > 0
      ? practiceSessions[currentSessionIndex - 1]
      : undefined;

  const nextSession =
    currentSessionIndex < practiceSessions.length - 1
      ? practiceSessions[currentSessionIndex + 1]
      : undefined;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Navigation between practice days */}
        <PracticeNavigation
          currentSession={currentSession}
          previousSession={previousSession}
          nextSession={nextSession}
          onPrevious={goToPrevious}
          onNext={goToNext}
        />

        <div className="my-6">
          <h2 className="text-2xl font-bold text-blue-600 mb-2">
            {currentSession.focus}
          </h2>
          <p className="text-lg mb-2">
            {currentSession.day} {(() => {
              try {
                const date = new Date(currentSession.startTime);
                // Check if date is valid
                if (Number.isNaN(date.getTime())) {
                  return "";
                }
                return date.toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                });
              } catch (_e) {
                return "";
              }
            })()}
            {countdown && countdown !== "0" && `${countdown}`}
          </p>

          {/* Special instructions for 2018 NATS */}
          {currentSession.focus === "2018 NATS" && (
            <div className="bg-yellow-50 border border-yellow-200 p-4 rounded-lg text-left mt-4">
              <h3 className="font-bold mb-2 text-yellow-800">
                Special Instructions for 2018 NATS:
              </h3>
              <ul className="list-disc pl-5 space-y-2 text-yellow-700">
                <li>
                  <strong>Everyone:</strong> Try to take the sprint as soon as
                  you can.
                </li>
                <li>
                  <strong>Extra time:</strong> Use any time past the 40-minute
                  sprint period to give EVERY PROBLEM at least 90-120 seconds of
                  attention.
                </li>
                <li>
                  <strong>Note:</strong> This approach is different than what
                  you're used to (where you present the problems you solved).
                  The 2018 problems are particularly challenging so having some
                  context on all problems will help when we review the answers.
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Practice session details */}
        <PracticeDetails
          session={currentSession}
          countdown={countdown}
          loadingZoom={loadingZoom}
          sessionHasEnded={sessionHasEnded}
        />

        {/* Problem sets */}
        <ProblemSetList problemSets={currentSession.problemSets} />

        {/* Session notes */}
        <SessionNotes session={currentSession} visible={showNotes} />
      </div>
    </div>
  );
};

export default Practice;
