import type React from "react";
import type { PracticeSession } from "shared-types";

interface SessionNotesProps {
  session: PracticeSession;
  visible: boolean;
}

const SessionNotes: React.FC<SessionNotesProps> = ({ session, visible }) => {
  if (!visible) return null;

  return (
    <div className="mt-6 p-4 bg-yellow-50 border-l-4 border-yellow-500">
      <h3 className="text-lg font-semibold mb-2">Show Notes</h3>
      <p>
        Here are some notes on your performance in the {session.focus} problems.
        You did well on the {session.problemSets[0].title}, but there are areas
        for improvement in the {session.problemSets[1].title}. Let's review your
        approach to problems in our next session.
      </p>
    </div>
  );
};

export default SessionNotes;
