import { type FC, useEffect, useState } from "react";
import type {
  AIMEProblemDetails,
  AMCProblemDetails,
  Exam,
  ExamUpdateData,
  OlympiadProblemDetails,
  ProblemDetails,
  ProblemReference,
  ShortAnswerProblemDetails,
} from "shared-types";
import {
  createProblem,
  labelProblemForExam,
  updateExam,
} from "../api/examService";
import ProblemService from "../services/ProblemService";
import DraggableProblemList from "./DraggableProblemList";
import ProblemCreator from "./ProblemCreator";

export type ExamProblem = ProblemReference &
  (
    | AMCProblemDetails
    | AIMEProblemDetails
    | ShortAnswerProblemDetails
    | OlympiadProblemDetails
  );

interface ExamEditorProps {
  exam: Exam & { id: string; problems: ExamProblem[] };
}

const ExamEditor: FC<ExamEditorProps> = ({ exam }) => {
  const [creatingProblem, setCreatingProblem] = useState(false);
  const [examType, setExamType] = useState<Exam["examType"]>(
    exam.examType || "other",
  );
  const [problemsToConvert, setProblemsToConvert] = useState<string[]>([]);
  const [problems, setProblems] = useState<ExamProblem[]>(exam.problems);
  const [error, setError] = useState<string | null>(null);
  const [showSortOption, setShowSortOption] = useState(false);
  const [syncStatus, setSyncStatus] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (examType !== exam.examType) {
      const problemsNeedingConversion = problems.filter((problem) => {
        if (examType.startsWith("AMC") && problem.type !== "multiple_choice")
          return true;
        if (
          (examType === "AIME" || examType === "ARML") &&
          problem.type !== "short_answer"
        )
          return true;
        if (examType === "Olympiad" && problem.type !== "essay") return true;
        return false;
      });
      setProblemsToConvert(
        problemsNeedingConversion
          .map((p) => p.problemId)
          .filter((id): id is string => id !== undefined),
      );
    } else {
      setProblemsToConvert([]);
    }
  }, [examType, problems, exam.examType]);

  useEffect(() => {
    const labels = problems
      .map((p) => p.label)
      .filter((label): label is string => label !== undefined)
      .map((label) => Number.parseInt(label));
    setShowSortOption(!labels.every((label, index) => label === index + 1));
  }, [problems]);

  const handleSaveProblem = async (
    problemId: string,
    updatedProblem: ProblemDetails,
  ) => {
    try {
      const result = await ProblemService.updateProblem(
        problemId,
        updatedProblem,
      );

      if (result.success) {
        console.log("Problem updated successfully");
        setProblems((prevProblems) =>
          prevProblems.map((p) =>
            p.problemId === problemId ? { ...p, ...updatedProblem } : p,
          ),
        );
      } else {
        throw new Error(result.error || "Failed to update problem");
      }
    } catch (error) {
      console.error("Error updating problem:", error);
      setError("Failed to update problem. Please try again.");
    }
  };

  const handleCreateProblem = async (
    newProblem: ProblemDetails,
    label: string,
  ) => {
    try {
      const createResult = await createProblem(newProblem);

      if (createResult.success && createResult.problemId) {
        const labelResult = await labelProblemForExam(
          exam.id,
          createResult.problemId,
          label,
        );
        const labelSuccess = labelResult.success;

        const newProblemRef: ExamProblem = {
          examId: exam.id,
          problemId: createResult.problemId,
          label,
          ...newProblem,
        } as ExamProblem;
        setProblems((prevProblems) => [...prevProblems, newProblemRef]);
        const newStatus: Record<string, boolean> = {};
        newStatus[createResult.problemId] = labelSuccess;
        setSyncStatus((prevStatus) => ({ ...prevStatus, ...newStatus }));
        setCreatingProblem(false);
      } else {
        throw new Error("Failed to create problem");
      }
    } catch (error) {
      console.error("Error in problem creation process:", error);
      setError("Failed to create problem. Please try again.");
    }
  };

  const handleLabelProblem = async (problemId: string, label: string) => {
    try {
      const result = await labelProblemForExam(exam.id, problemId, label);
      const { success } = result;

      const newStatus: Record<string, boolean> = {};
      newStatus[problemId] = success;
      setSyncStatus((prevStatus) => ({ ...prevStatus, ...newStatus }));
      if (!success) {
        throw new Error("Failed to label problem for exam");
      }
    } catch (error) {
      console.error("Error labeling problem for exam:", error);
      setError("Failed to synchronize problem. Please try again.");
    }
  };

  const getSuggestedLabel = () => {
    if (problems.length === 0) return "1";
    const lastProblem = problems[problems.length - 1];
    if (!lastProblem.label) return `${problems.length + 1}`;
    const numericPart = Number.parseInt(lastProblem.label.replace(/\D/g, ""));
    return Number.isNaN(numericPart)
      ? `${problems.length + 1}`
      : `${numericPart + 1}`;
  };

  const handleUpdateExam = async () => {
    try {
      const result = await updateExam(exam.id, examType, problemsToConvert);

      if (result.success) {
        console.log("Exam updated successfully");
        setProblems((prevProblems) =>
          prevProblems.map((p) => {
            if (problemsToConvert.includes(p.problemId)) {
              const updatedProblem = { ...p };
              if (examType.startsWith("AMC")) {
                updatedProblem.type = "multiple_choice";
              } else if (examType === "AIME" || examType === "ARML") {
                updatedProblem.type = "short_answer";
              } else if (examType === "Olympiad") {
                updatedProblem.type = "essay";
              }
              return updatedProblem;
            }
            return p;
          }),
        );
        setProblemsToConvert([]);
        setError(null);
      } else {
        throw new Error("Failed to update exam");
      }
    } catch (error) {
      console.error("Error updating exam:", error);
      setError("Failed to update exam. Please try again.");
    }
  };

  const toggleProblemConversion = (problemId: string) => {
    setProblemsToConvert((prev) =>
      prev.includes(problemId)
        ? prev.filter((id) => id !== problemId)
        : [...prev, problemId],
    );
  };

  const handleSortProblems = () => {
    const sortedProblems = [...problems].sort((a, b) => {
      const labelA = a.label ? Number.parseInt(a.label) : 0;
      const labelB = b.label ? Number.parseInt(b.label) : 0;
      return labelA - labelB;
    });
    setProblems(sortedProblems);
    setShowSortOption(false);
  };

  return (
    <div>
      <div className="bg-gray-100 p-4 mb-4 rounded-lg">
        <h2 className="text-2xl font-bold mb-2">Editing Exam: {exam.title}</h2>
        <p>
          <strong>Competition:</strong> {exam.competition}
        </p>
        <p>
          <strong>Year:</strong> {exam.year}
        </p>
        <p>
          <strong>Name:</strong> {exam.name}
        </p>
      </div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          {error}
        </div>
      )}
      <div className="mb-4">
        <label htmlFor="examType" className="block font-bold mb-2">
          Exam Type:
        </label>
        <select
          id="examType"
          value={examType}
          onChange={(e) => setExamType(e.target.value as Exam["examType"])}
          className="w-full p-2 border rounded"
        >
          <option value="other">Other</option>
          <option value="AMC-8">AMC-8</option>
          <option value="AMC-10">AMC-10</option>
          <option value="AMC-12">AMC-12</option>
          <option value="AIME">AIME</option>
          <option value="ARML">ARML</option>
          <option value="Olympiad">Olympiad</option>
        </select>
      </div>
      {problemsToConvert.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">Problems to Convert:</h3>
          {problems.map((problem) => (
            <div key={problem.problemId} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`convert-${problem.problemId}`}
                checked={problemsToConvert.includes(problem.problemId)}
                onChange={() => toggleProblemConversion(problem.problemId)}
                className="mr-2"
              />
              <label htmlFor={`convert-${problem.problemId}`}>
                Problem {problem.label} (Current Type: {problem.type})
              </label>
            </div>
          ))}
        </div>
      )}
      <button
        onClick={handleUpdateExam}
        className="mb-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        type="button"
      >
        Update Exam
      </button>
      <button
        onClick={() => setCreatingProblem(true)}
        className="mb-4 ml-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        type="button"
      >
        Add New Problem
      </button>
      {showSortOption && (
        <button
          onClick={handleSortProblems}
          className="mb-4 ml-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          type="button"
        >
          Sort Problems
        </button>
      )}
      {creatingProblem && (
        <div className="mb-4 p-4 border rounded">
          <h3 className="text-xl font-semibold mb-2">Create New Problem</h3>
          <ProblemCreator
            onSave={handleCreateProblem}
            onCancel={() => setCreatingProblem(false)}
            suggestedLabel={getSuggestedLabel()}
          />
        </div>
      )}
      <DraggableProblemList
        problems={problems as Partial<ProblemReference>[]}
        setProblems={
          setProblems as React.Dispatch<
            React.SetStateAction<Partial<ProblemReference>[]>
          >
        }
        handleSaveProblem={handleSaveProblem}
        handleLabelProblem={handleLabelProblem}
        syncStatus={syncStatus}
      />
    </div>
  );
};

export default ExamEditor;
