import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import type { UserProfile } from "../types";

import { listUsers } from "../api/userService";

const Users: React.FC = () => {
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const {
    currentUser,
    userProfile,
    viewAsUserProfile,
    setViewAsUserProfile,
    isAdminMode,
  } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      if (!currentUser || !isAdminMode) return;

      try {
        setLoading(true);
        const result = await listUsers();
        if (Array.isArray(result.users)) {
          // Ensure all user profiles have complete data
          const completeUsers = result.users.map(
            (user): UserProfile => ({
              id: user.id,
              uid: user.id || "", // Ensure uid is never undefined
              name: user.name,
              email: user.email,
              avatar: user.avatar || "",
              role: user.role,
              isAdmin: !!user.isAdmin,
              isStaff: !!user.isStaff,
              createdAt: user.createdAt || new Date().toISOString(),
              lastLogin: user.lastLogin || new Date().toISOString(),
              progress: user.progress || [],
              achievements: user.achievements || [],
              testsTaken: user.testsTaken || [],
            }),
          );

          setUsers(completeUsers);
        } else {
          throw new Error("Unexpected data structure");
        }
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred",
        );
      } finally {
        setLoading(false);
      }
    };

    if (userProfile?.isAdmin && isAdminMode) {
      fetchUsers();
    }
  }, [currentUser, userProfile?.isAdmin, isAdminMode]);

  const handleViewAs = useCallback(
    (user: UserProfile) => {
      console.log("Setting view as user:", user);
      setViewAsUserProfile(user);
    },
    [setViewAsUserProfile],
  );

  if (!userProfile?.isAdmin || !isAdminMode) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Users</h1>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Admin
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Staff
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Login
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user.email}>
                <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.role}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.isAdmin ? "Yes" : "No"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.isStaff ? "Yes" : "No"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {new Date(user.lastLogin).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleViewAs(user)}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded text-sm"
                    type="button"
                    disabled={viewAsUserProfile?.email === user.email}
                  >
                    {viewAsUserProfile?.email === user.email
                      ? "Currently Viewing"
                      : "View As"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
