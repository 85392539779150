import { makeAPICall } from "../api/apiClient";
import type { ProblemDetails } from "../types";

/**
 * Functions for managing problem operations
 */

/**
 * Updates a problem in Firestore
 * @param problemId The ID of the problem to update
 * @param updatedProblem The updated problem details
 * @returns A promise that resolves to a success indicator
 */
export async function updateProblem(
  problemId: string,
  updatedProblem: ProblemDetails,
): Promise<{ success: boolean; error?: string }> {
  try {
    console.log("ProblemService: Updating problem", problemId, updatedProblem);

    // Use the same endpoint and data structure as the Python implementation
    const result = await makeAPICall<
      { problemId: string; updates: ProblemDetails },
      { success: boolean }
    >("problemApi-update", {
      problemId,
      updates: updatedProblem,
    });

    if (result.success) {
      console.log("ProblemService: Problem updated successfully");
      return { success: true };
    }

    console.error("ProblemService: API returned failure");
    return { success: false, error: "API returned failure" };
  } catch (error) {
    console.error("ProblemService: Error updating problem:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error",
    };
  }
}

// Export as default for backward compatibility
const ProblemService = {
  updateProblem,
};

export default ProblemService;
