import { useCallback, useEffect, useState } from "react";
import type { AIMEData } from "shared-types";
import { makeAPICall } from "../api/apiClient";

export const useAIMEData = () => {
  const [aimeData, setAIMEData] = useState<AIMEData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAIMEData = useCallback(async () => {
    try {
      setAIMEData(await makeAPICall<void, AIMEData>("getAIMEData"));
      setError(null);
    } catch (err) {
      console.error("Error fetching AIME data:", err);
      setError("Failed to load AIME data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAIMEData();
  }, [fetchAIMEData]);

  return {
    aimeData,
    loading,
    error,
    refreshData: fetchAIMEData,
  };
};
