import { CalendarDays, ChevronRight } from "lucide-react";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { AIMEData } from "shared-types";
import { useAIMEData } from "../hooks/useAIMEData";
import AIMEAdminConsole from "./AIMEAdminConsole";
import type { ProblemSet } from "./ProblemSetSection";
import ProblemSetSection from "./ProblemSetSection";

const initialProblemSets: ProblemSet[] = [
  {
    id: "PXNzNd1WAquj1OaRRsw6",
    title: "Target Practice - AIME 1983-1984 1-8",
    availableDate: new Date("2024-11-13T00:00:00.000Z"),
    problems: [
      {
        id: "cPebS0qCoNxQdRwDzO41",
        label: "1",
        userAnswer: null,
        timeSpent: null,
      },
      {
        id: "C6SyDJH3PmOXzRNkt97y",
        label: "8",
        userAnswer: null,
        timeSpent: null,
      },
    ],
    challengeId: "PXNzNd1WAquj1OaRRsw6",
  },
  {
    id: "4oVPZUdCJrxVUloMnU9f",
    title: "Target Practice - AIME 1983-1984 1-9",
    availableDate: new Date("2024-11-14T00:00:00.000Z"),
    problems: [
      {
        id: "ywwu7E0dsyYFlF4W9Pee",
        label: "1",
        userAnswer: null,
        timeSpent: null,
      },
      {
        id: "bKubmugcdqoYagsF7vpd",
        label: "9",
        userAnswer: null,
        timeSpent: null,
      },
    ],
    challengeId: "4oVPZUdCJrxVUloMnU9f",
  },
];

const AIMEPortal: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();
  const { aimeData, loading, error } = useAIMEData();
  const [simulatedData, setSimulatedData] = useState<AIMEData | null>(null);
  const [problemSets, setProblemSets] =
    useState<ProblemSet[]>(initialProblemSets);

  const handleSimulateResponse = useCallback((newData: AIMEData) => {
    setSimulatedData(newData);
  }, []);

  const dataToUse = simulatedData || aimeData;

  useEffect(() => {
    if (!dataToUse) return;

    const updatedProblemSets = [...initialProblemSets];
    const problemSetMap = new Map<string, ProblemSet>();

    // Create lookup map of existing problem sets
    for (const set of updatedProblemSets) {
      problemSetMap.set(set.challengeId || set.id, set);
    }

    // Process each challenge from the API data
    for (const challenge of dataToUse.challenges) {
      const existingSet = problemSetMap.get(challenge.id);
      const progress = dataToUse.userProgress.find(
        (p) => p.challengeId === challenge.id,
      );

      if (existingSet) {
        // Update existing problem set
        const updatedSet = {
          ...existingSet,
          challengeId: challenge.id,
          problems: existingSet.problems?.map((problem) => {
            const problemResponse = progress?.problemResponses.find(
              (pr) => problem.label === pr.label,
            );

            if (problemResponse) {
              return {
                ...problem,
                userAnswer: problemResponse.answer ?? problem.userAnswer,
                timeSpent:
                  problemResponse.timeSpent?.toString() ?? problem.timeSpent,
                isCorrect: problemResponse.isCorrect ?? problem.isCorrect,
                submittedAt: problemResponse.submittedAt
                  ? new Date(problemResponse.submittedAt)
                  : problem.submittedAt,
              };
            }
            return problem;
          }),
        };

        const index = updatedProblemSets.findIndex(
          (set) => set.id === updatedSet.id,
        );
        if (index !== -1) {
          updatedProblemSets[index] = updatedSet;
        }
      } else {
        // Create new problem set
        const newSet: ProblemSet = {
          id: challenge.id,
          title: challenge.name,
          availableDate: new Date(challenge.availableDate),
          challengeId: challenge.id,
          problems: challenge.problems.map((problem) => {
            const problemResponse = progress?.problemResponses.find(
              (pr) => problem.label === pr.label,
            );

            return {
              id: problem.problemId,
              label: problem.label,
              userAnswer: problemResponse?.answer ?? null,
              timeSpent: problemResponse?.timeSpent?.toString() ?? null,
              isCorrect: problemResponse?.isCorrect,
              submittedAt: problemResponse?.submittedAt
                ? new Date(problemResponse.submittedAt)
                : undefined,
            };
          }),
        };
        updatedProblemSets.push(newSet);
      }
    }

    // Sort by available date
    updatedProblemSets.sort(
      (a, b) => a.availableDate.getTime() - b.availableDate.getTime(),
    );
    setProblemSets(updatedProblemSets);
  }, [dataToUse]);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const adjustDate = (days: number) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + days);
    setCurrentDate(newDate);
  };

  const handleStartChallenge = (challengeId: string) => {
    navigate(`/challenge/${challengeId}`);
  };

  const handleRequestUnlock = () => {
    alert("Request for early access sent to instructor");
  };

  if (loading && !simulatedData) {
    return <div className="p-6">Loading AIME data...</div>;
  }

  if (error && !simulatedData) {
    console.error("Error loading AIME data:", error);
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900">
            AIME Practice Portal
          </h2>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="text-sm text-gray-600">
                {problemSets.length} problem sets available
              </div>
            </div>
          </div>

          <div className="space-y-4">
            {problemSets.map((set) => (
              <ProblemSetSection
                key={set.id}
                problemSet={set}
                currentDate={currentDate}
                onStartChallenge={handleStartChallenge}
                onRequestUnlock={handleRequestUnlock}
              />
            ))}
          </div>

          <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
            <div className="flex items-center text-blue-800 font-semibold">
              Ready for more practice? <ChevronRight className="w-4 h-4 ml-1" />
            </div>
            <p className="text-blue-700 mt-1">
              Return tomorrow to unlock your next problem set and continue your
              AIME preparation journey.
            </p>
          </div>
        </div>
      </div>

      {/* Admin Time Control Panel */}
      <div className="bg-gray-50 rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
            <CalendarDays className="w-5 h-5" />
            Time Control Panel
          </h3>
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => adjustDate(-1)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                type="button"
              >
                Previous Day
              </button>
              <button
                onClick={() => adjustDate(1)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                type="button"
              >
                Next Day
              </button>
            </div>
            <div className="text-lg font-medium text-gray-900">
              Current Date: {formatDate(currentDate)}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <AIMEAdminConsole
          lastAPIResponse={aimeData}
          onSimulateResponse={handleSimulateResponse}
        />
      </div>
    </div>
  );
};

export default AIMEPortal;
