import type React from "react";
import { useNavigate } from "react-router-dom";
import type { Exam } from "shared-types";

interface ARMLProblemSetListProps {
  problemSets: Exam[];
  isViewingAsOtherUser: boolean;
}

interface ARMLYearSet {
  year: string;
  Individual?: Exam;
  Relay?: Exam;
  Team?: Exam;
  Power?: Exam;
}

const ExamButton: React.FC<{
  year: string;
  type: string;
  exam: Exam;
  isViewingAsOtherUser: boolean;
  onClick: () => void;
}> = ({ year, type, isViewingAsOtherUser, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={isViewingAsOtherUser}
    className={`
      px-4 py-2 text-sm text-gray-800 rounded
      ${
        isViewingAsOtherUser
          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
          : "bg-gradient-to-r from-gray-100 to-gray-200 hover:from-gray-200 hover:to-gray-300 transition-all"
      }
    `}
  >
    {year} {type}
  </button>
);

const ARMLProblemSetList: React.FC<ARMLProblemSetListProps> = ({
  problemSets,
  isViewingAsOtherUser,
}) => {
  const navigate = useNavigate();

  // Filter for ARML exams
  const armlExams = problemSets.filter((exam) => exam.competition === "ARML");

  if (!armlExams?.length) {
    return <div className="text-gray-500">No ARML problem sets available</div>;
  }

  const setsByYear = armlExams.reduce<Record<string, ARMLYearSet>>(
    (acc, set) => {
      const year = set.year;
      if (!acc[year]) {
        acc[year] = { year };
      }

      switch (set.examType) {
        case "Individual":
          acc[year].Individual = set;
          break;
        case "Relay":
          acc[year].Relay = set;
          break;
        case "Team":
          acc[year].Team = set;
          break;
        case "Power":
          acc[year].Power = set;
          break;
      }

      return acc;
    },
    {},
  );

  const sortedYears = Object.keys(setsByYear).sort(
    (a, b) => Number(a) - Number(b),
  );

  const examTypes = [
    { key: "Individual", label: "Individual", type: "Individual" },
    { key: "Relay", label: "Relay", type: "Relay" },
    { key: "Team", label: "Team", type: "Team" },
    { key: "Power", label: "Power", type: "Power" },
  ] as const;

  return (
    <div className="space-y-2">
      {sortedYears.map((year) => (
        <div key={year} className="flex flex-wrap gap-2">
          {examTypes.map(({ key, label }) => {
            const exam = setsByYear[year][key];
            return exam ? (
              <ExamButton
                key={key}
                year={year}
                type={label}
                exam={exam}
                isViewingAsOtherUser={isViewingAsOtherUser}
                onClick={() =>
                  !isViewingAsOtherUser && navigate(`/exam/${exam.id}`)
                }
              />
            ) : null;
          })}
        </div>
      ))}
    </div>
  );
};

export default ARMLProblemSetList;
