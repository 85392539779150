import "katex/dist/katex.min.css";
import type React from "react";
import Latex from "react-latex-next";

interface LatexRendererProps {
  latex?: string;
}

const LatexRenderer: React.FC<LatexRendererProps> = ({ latex = "" }) => {
  return <Latex>{latex}</Latex>;
};

export default LatexRenderer;
