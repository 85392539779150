import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Renderer } from "../asymptote/engine/renderer";
import { Parser } from "../asymptote/parser/parser";

const AsymptoteRendererDemo: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleRender = () => {
    try {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const parser = new Parser();
      const commands = parser.parse(code);
      const renderer = new Renderer(canvas);
      renderer.execute(commands);
      setError("");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("Failed to render");
      }
    }
  };

  const insertExample = () => {
    setCode("draw((0,0)--(100,100));");
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
  }, []);

  return (
    <div className="p-6">
      <div className="mb-6">
        <h2 className="text-lg font-medium mb-2">
          Asymptote Engine Test Interface
        </h2>
        <div className="text-sm">
          Supported: line drawing via draw((x1,y1)--(x2,y2));
        </div>
      </div>

      <div className="flex gap-6">
        <div className="w-1/2 space-y-4">
          <button
            onClick={insertExample}
            className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded text-sm"
            type="button"
          >
            Insert Example Line
          </button>

          <textarea
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full h-48 p-2 font-mono text-sm border rounded resize-none"
            placeholder="Enter Asymptote code"
          />

          {error && (
            <div className="p-2 bg-red-100 border border-red-200 text-red-700 text-sm rounded">
              {error}
            </div>
          )}

          <button
            onClick={handleRender}
            className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
            type="button"
          >
            Render
          </button>
        </div>

        <div className="w-1/2">
          <canvas
            ref={canvasRef}
            width={400}
            height={400}
            className="w-full border bg-white"
          />
        </div>
      </div>
    </div>
  );
};

export default AsymptoteRendererDemo;
