import { ChevronLeft, ChevronRight } from "lucide-react";
import type React from "react";
import type { PracticeSession } from "shared-types";

interface PracticeNavigationProps {
  currentSession: PracticeSession;
  previousSession?: PracticeSession;
  nextSession?: PracticeSession;
  onPrevious: () => void;
  onNext: () => void;
}

const PracticeNavigation: React.FC<PracticeNavigationProps> = ({
  currentSession,
  previousSession,
  nextSession,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="flex items-center justify-between mb-5">
      <button
        type="button"
        onClick={onPrevious}
        disabled={!previousSession}
        className={`px-4 py-2 rounded bg-gray-100 hover:bg-gray-200 flex items-center ${
          !previousSession ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <ChevronLeft className="w-4 h-4 mr-1" />
        {previousSession?.title}
      </button>

      <h1 className="text-xl font-bold text-center text-blue-800 flex-grow">
        {currentSession.title}
      </h1>

      <button
        type="button"
        onClick={onNext}
        disabled={!nextSession}
        className={`px-4 py-2 rounded bg-gray-100 hover:bg-gray-200 flex items-center ${
          !nextSession ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        {nextSession?.title}
        <ChevronRight className="w-4 h-4 ml-1" />
      </button>
    </div>
  );
};

export default PracticeNavigation;
