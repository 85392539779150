import type React from "react";
import type { ProblemFormProps } from "../types";
import LatexRenderer from "./LatexRenderer";
import ProblemTypeContent from "./ProblemTypeContent";

const ProblemForm: React.FC<ProblemFormProps> = ({ problem, onChange }) => {
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    onChange({ ...problem, [name]: value });
  };

  const handleTypeContentUpdate = (field: string, value: string) => {
    // If the field is "options" and the value is a JSON string, parse it
    if (field === "options" && value.startsWith("{")) {
      try {
        const options = JSON.parse(value);
        // Only set options if this is a multiple_choice problem
        if (problem.type === "multiple_choice") {
          onChange({
            ...problem,
            options: options as Record<string, string>,
          });
        } else {
          console.warn("Options can only be set on multiple_choice problems");
        }
      } catch (error) {
        console.error("Error parsing options JSON:", error);
      }
    } else {
      onChange({ ...problem, [field]: value });
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="type" className="block font-bold mb-1">
          Problem Type
        </label>
        <select
          id="type"
          name="type"
          value={problem.type}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
        >
          <option value="multiple_choice">Multiple Choice</option>
          <option value="short_answer">Short Answer</option>
          <option value="essay">Essay</option>
        </select>
      </div>

      <div>
        <label htmlFor="statement" className="block font-bold mb-1">
          Problem Statement
        </label>
        <textarea
          id="statement"
          name="statement"
          value={problem.statement}
          onChange={handleInputChange}
          rows={5}
          className="w-full p-2 border rounded"
        />
        <div className="mt-2 p-3 border rounded bg-gray-50">
          <h4 className="font-semibold mb-1">Preview:</h4>
          <div className="prose">
            <LatexRenderer latex={problem.statement} />
          </div>
        </div>
      </div>

      <div className="border p-4 rounded bg-gray-50">
        <h3 className="font-bold mb-2">Image Properties</h3>
        <div className="space-y-3">
          <div>
            <label htmlFor="imageUrl" className="block font-bold mb-1">
              Image URL
            </label>
            <input
              id="imageUrl"
              type="text"
              value={problem.image?.url || ""}
              onChange={(e) => {
                const newImage = problem.image
                  ? { ...problem.image, url: e.target.value }
                  : { url: e.target.value };
                onChange({ ...problem, image: newImage });
              }}
              className="w-full p-2 border rounded"
              placeholder="https://example.com/image.png"
            />
          </div>
          <div>
            <label htmlFor="imageAlt" className="block font-bold mb-1">
              Image Alt Text
            </label>
            <input
              id="imageAlt"
              type="text"
              value={problem.image?.alt || ""}
              onChange={(e) => {
                const newImage = problem.image
                  ? { ...problem.image, alt: e.target.value }
                  : { url: "", alt: e.target.value };
                onChange({ ...problem, image: newImage });
              }}
              className="w-full p-2 border rounded"
              placeholder="Description of the image"
            />
          </div>
          {problem.image?.url && (
            <div className="mt-2">
              <p className="font-bold mb-1">Image Preview:</p>
              <img
                src={problem.image.url}
                alt={problem.image.alt || "Problem image"}
                className="max-w-full h-auto border rounded"
              />
            </div>
          )}
        </div>
      </div>

      <div className="border p-4 rounded bg-gray-50">
        <h3 className="font-bold mb-2">Image Properties</h3>
        <div className="space-y-3">
          <div>
            <label htmlFor="imageUrl" className="block font-bold mb-1">
              Image URL
            </label>
            <input
              id="imageUrl"
              type="text"
              value={problem.image?.url || ""}
              onChange={(e) => {
                const newImage = problem.image
                  ? { ...problem.image, url: e.target.value }
                  : { url: e.target.value };
                onChange({ ...problem, image: newImage });
              }}
              className="w-full p-2 border rounded"
              placeholder="https://example.com/image.png"
            />
          </div>
          <div>
            <label htmlFor="imageAlt" className="block font-bold mb-1">
              Image Alt Text
            </label>
            <input
              id="imageAlt"
              type="text"
              value={problem.image?.alt || ""}
              onChange={(e) => {
                const newImage = problem.image
                  ? { ...problem.image, alt: e.target.value }
                  : { url: "", alt: e.target.value };
                onChange({ ...problem, image: newImage });
              }}
              className="w-full p-2 border rounded"
              placeholder="Description of the image"
            />
          </div>
          {problem.image?.url && (
            <div className="mt-2">
              <p className="font-bold mb-1">Image Preview:</p>
              <img
                src={problem.image.url}
                alt={problem.image.alt || "Problem image"}
                className="max-w-full h-auto border rounded"
              />
            </div>
          )}
        </div>
      </div>

      <ProblemTypeContent
        problem={problem}
        mode="edit"
        onUpdate={handleTypeContentUpdate}
      />
    </div>
  );
};

export default ProblemForm;
