import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import UpdateUserProfile from "./UpdateUserProfile";

const UserProfile: React.FC = () => {
  const {
    userProfile,
    profileError,
    profileLoading,
    refreshUserProfile,
    generateLoginToken,
  } = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const originalTitle = useRef(document.title);

  useEffect(() => {
    refreshUserProfile();
  }, [refreshUserProfile]);

  // Update page title
  useEffect(() => {
    document.title = "User Profile - GrindOlympiads";

    return () => {
      document.title = originalTitle.current;
    };
  }, []);

  const handleUpdateComplete = useCallback(() => {
    setIsEditing(false);
  }, []);

  if (profileLoading) {
    return <div>Loading profile...</div>;
  }

  if (profileError) {
    return <div className="text-red-500">Error: {profileError}</div>;
  }

  if (!userProfile) {
    return <div>No profile data available.</div>;
  }

  if (isEditing) {
    return (
      <UpdateUserProfile
        onUpdate={handleUpdateComplete}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  const displayToken = userProfile.loginToken?.token || token;
  const loginUrl = displayToken
    ? `grindolympiads.com/tokenlogin?token=${displayToken}`
    : "";

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">User Profile</h1>
        <button
          type="button"
          onClick={() => setIsEditing(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Edit Profile
        </button>
      </div>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
        <div className="mb-4">
          <img
            src={userProfile.avatar}
            alt={userProfile.name}
            className="w-24 h-24 rounded-full mx-auto"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <p className="text-gray-700" id="name">
            {userProfile.name}
          </p>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <p className="text-gray-700" id="email">
            {userProfile.email}
          </p>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="role"
          >
            Role
          </label>
          <p className="text-gray-700" id="role">
            {userProfile.role}
          </p>
        </div>
        {userProfile.isAdmin && (
          <div className="mb-4">
            <p className="text-green-500 font-bold">Admin</p>
          </div>
        )}
        {userProfile.isStaff && (
          <div className="mb-4">
            <p className="text-blue-500 font-bold">Staff</p>
          </div>
        )}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="created-at"
          >
            Account Created
          </label>
          <p className="text-gray-700" id="created-at">
            {new Date(userProfile.createdAt).toLocaleString("en-US", {
              timeZone: "UTC",
            })}
          </p>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="last-login"
          >
            Last Login
          </label>
          <p className="text-gray-700" id="last-login">
            {new Date(userProfile.lastLogin).toLocaleString("en-US", {
              timeZone: "UTC",
            })}
          </p>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="login-token"
          >
            Login Token
          </label>
          {displayToken ? (
            <div>
              <div data-testid="token-display" className="text-gray-700 mb-2">
                {displayToken}
              </div>
              {userProfile.loginToken?.expiresAt && (
                <p className="text-sm text-gray-500 mb-2">
                  Expires at:{" "}
                  {new Date(userProfile.loginToken.expiresAt).toLocaleString()}
                </p>
              )}
              <p className="text-sm text-gray-700 mb-4">
                Use this URL to log in on another device:
                <br />
                <code className="bg-gray-100 px-2 py-1 rounded">
                  {loginUrl}
                </code>
              </p>
            </div>
          ) : (
            <div>
              <button
                type="button"
                onClick={async () => {
                  try {
                    setTokenError(null);
                    const newToken = await generateLoginToken();
                    if (newToken) {
                      setToken(newToken);
                    } else {
                      setTokenError("Failed to generate token");
                    }
                  } catch (_error) {
                    setTokenError("Error generating token");
                  }
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Generate Login Token
              </button>
              {tokenError && (
                <p className="text-red-500 text-sm mt-2">{tokenError}</p>
              )}
            </div>
          )}
        </div>

        {userProfile.testsTaken && userProfile.testsTaken.length > 0 && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="tests-taken"
            >
              Tests Taken
            </label>
            <ul className="list-disc list-inside" id="tests-taken">
              {userProfile.testsTaken.map((test) => (
                <li key={test} className="text-gray-700">
                  {test}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
