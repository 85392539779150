import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type {
  AIMEProblemDetails,
  AMCProblemDetails,
  BaseProblemDetails,
  ChallengeData,
  ChallengeRun,
  Exam,
  OlympiadProblemDetails,
  ProblemDetails,
  ProblemReference,
  ShortAnswerProblemDetails,
} from "shared-types";
import { useAuth } from "../contexts/AuthContext";
import { challengeService } from "../services/ChallengeService";
import { examService } from "../services/ExamService";
import ChallengeEditor from "./ChallengeEditor";
import ConfirmModal from "./ConfirmModal";
import CreateExam from "./CreateExam";
import { ExamContent } from "./ExamContent";
import ExamEditor, { type ExamProblem } from "./ExamEditor";
import PhilosophyModal from "./PhilosophyModal";
import TrainingModal from "./TrainingModal";

const ExamComponent: React.FC = () => {
  const { examId, challengeId } = useParams<{
    examId?: string;
    challengeId?: string;
  }>();
  const [exam, setExam] = useState<Exam | null>(null);
  const [challenge, setChallenge] = useState<ChallengeData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [showPhilosophyModal, setShowPhilosophyModal] = useState(false);
  const [showExamContent, setShowExamContent] = useState(false);
  const [challengeRun, setChallengeRun] = useState<ChallengeRun | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const originalTitle = useRef(document.title);

  const { userProfile, viewAsUserProfile, isAdminMode } = useAuth();
  const navigate = useNavigate();
  const isViewingAsOtherUser =
    isAdminMode && viewAsUserProfile?.email !== userProfile?.email;

  const mapProblemToExamProblem = useCallback(
    (
      problem: ProblemDetails & { problemId: string; label: string },
      examId: string,
    ): ExamProblem => {
      const base: ProblemReference = {
        examId,
        problemId: problem.problemId,
        label: problem.label,
      };

      switch (problem.type) {
        case "multiple_choice": {
          const amcProblem = problem as AMCProblemDetails;
          return {
            ...base,
            type: "multiple_choice",
            statement: amcProblem.statement,
            options: amcProblem.options,
            correctAnswer: amcProblem.correctAnswer,
            image: amcProblem.image,
            diagrams: amcProblem.diagrams,
          } as ProblemReference & AMCProblemDetails;
        }
        case "aime": {
          const aimeProblem = problem as AIMEProblemDetails;
          return {
            ...base,
            type: "aime",
            statement: aimeProblem.statement,
            correctAnswer: aimeProblem.correctAnswer,
            image: aimeProblem.image,
            diagrams: aimeProblem.diagrams,
          } as ProblemReference & AIMEProblemDetails;
        }
        case "short_answer": {
          const shortAnswerProblem = problem as ShortAnswerProblemDetails;
          return {
            ...base,
            type: "short_answer",
            statement: shortAnswerProblem.statement,
            correctAnswer: shortAnswerProblem.correctAnswer,
            image: shortAnswerProblem.image,
            diagrams: shortAnswerProblem.diagrams,
          } as ProblemReference & ShortAnswerProblemDetails;
        }
        case "essay": {
          const essayProblem = problem as OlympiadProblemDetails;
          return {
            ...base,
            type: "essay",
            statement: essayProblem.statement,
            solutionGuidelines: essayProblem.solutionGuidelines,
            image: essayProblem.image,
            diagrams: essayProblem.diagrams,
          } as ProblemReference & OlympiadProblemDetails;
        }
        default: {
          const unknownProblem = problem as BaseProblemDetails;
          throw new Error(
            `Unsupported problem type: ${unknownProblem.type || "unknown"}`,
          );
        }
      }
    },
    [],
  );

  const refreshChallengeState = useCallback(
    async (storedChallengeRun: ChallengeRun) => {
      try {
        const challenge = await challengeService.getChallenge(
          storedChallengeRun.challenge.id,
        );
        storedChallengeRun.challenge = challenge;
        setChallengeRun(storedChallengeRun);
        setShowExamContent(true);
      } catch (err) {
        console.error("Error refreshing challenge state:", err);
        setError("Failed to refresh challenge state. Please try again.");
      }
    },
    [],
  );

  // Update page title when challenge name is available
  useEffect(() => {
    if (challenge?.name) {
      document.title = `${challenge.name} - GrindOlympiads`;
    } else if (exam?.title) {
      document.title = `${exam.title} - GrindOlympiads`;
    }

    return () => {
      document.title = originalTitle.current;
    };
  }, [challenge, exam]);

  useEffect(() => {
    const fetchData = async () => {
      if (examId === "new") {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        if (challengeId) {
          const challengeDetails =
            await challengeService.getChallenge(challengeId);
          setChallenge(challengeDetails);
          setError(null);

          if (isAdminMode) {
            if (!isViewingAsOtherUser) {
              setShowExamContent(true);
            }
          } else {
            setShowConfirmModal(true);
          }
        } else if (examId) {
          const examDetails = await examService.getExam(examId);
          setExam(examDetails);
          setError(null);

          const storedChallengeRun = localStorage.getItem(
            `challenge_${examId}`,
          );

          if (isAdminMode) {
            if (!isViewingAsOtherUser) {
              setShowExamContent(true);
            }
          } else if (!storedChallengeRun) {
            setShowTrainingModal(true);
          } else {
            await refreshChallengeState(JSON.parse(storedChallengeRun));
          }
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    examId,
    challengeId,
    isAdminMode,
    refreshChallengeState,
    isViewingAsOtherUser,
  ]);

  const startChallenge = async (type: string): Promise<void> => {
    if (!examId || isViewingAsOtherUser) return;

    try {
      const challengeRun = await examService.startChallenge({
        examId,
        challengeType: type,
      });
      setChallengeRun(challengeRun);
      localStorage.setItem(`challenge_${examId}`, JSON.stringify(challengeRun));

      setShowTrainingModal(false);
      setShowExamContent(true);
    } catch (err) {
      console.error(`Error starting ${type} challenge:`, err);
      setError(`Failed to start ${type} challenge. Please try again.`);
    }
  };

  const handleStartExam = () => {
    if (isViewingAsOtherUser) return;
    setShowTrainingModal(false);
    setShowExamContent(true);
  };

  const handleDirectChallengeStart = async () => {
    if (!challengeId || isViewingAsOtherUser) return;

    try {
      const challengeRun = await examService.startChallenge({ challengeId });
      setChallengeRun(challengeRun);
      setShowExamContent(true);
    } catch (err) {
      console.error("Error starting challenge run:", err);
      setError("Failed to start the challenge. Please try again.");
    }
  };

  const handleFinish = () => {
    if (examId) {
      localStorage.removeItem(`challenge_${examId}`);
    }
    navigate("/");
  };

  if (loading) {
    return <div className="p-6 text-center">Loading data...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">Error: {error}</div>;
  }

  if (examId === "new") {
    return <CreateExam />;
  }

  if (!exam && !challenge) {
    return <div className="p-6 text-center">No data available.</div>;
  }

  if (isViewingAsOtherUser) {
    return (
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4">
          {exam ? exam.title : challenge?.name}
        </h1>
        <div className="text-gray-600">
          Cannot interact with challenges while viewing as{" "}
          {viewAsUserProfile?.name}
        </div>
      </div>
    );
  }

  const transformedExam =
    exam && examId
      ? {
          ...exam,
          id: examId,
          problems: exam.problems.map((p) => {
            const problemWithDetails = p as ProblemDetails & {
              problemId: string;
              label: string;
            };
            return mapProblemToExamProblem(problemWithDetails, examId);
          }),
        }
      : null;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">
        {exam ? exam.title : challenge?.name}
      </h1>
      {showExamContent &&
        (isAdminMode ? (
          challengeRun ? (
            <ChallengeEditor challengeRun={challengeRun} />
          ) : transformedExam ? (
            <ExamEditor exam={transformedExam} />
          ) : null
        ) : (
          challengeRun && (
            <ExamContent
              exam={exam}
              challengeRun={challengeRun}
              challengeProblems={challengeRun.challenge.problems}
              onFinish={handleFinish}
            />
          )
        ))}
      {exam && !isAdminMode && (
        <TrainingModal
          exam={exam}
          isOpen={showTrainingModal}
          onClose={() => setShowTrainingModal(false)}
          onTakeExam={handleStartExam}
          onStartChallenge={startChallenge}
          onViewPhilosophy={() => setShowPhilosophyModal(true)}
        />
      )}
      <PhilosophyModal
        isOpen={showPhilosophyModal}
        onClose={() => setShowPhilosophyModal(false)}
      />
      <ConfirmModal
        isOpen={showConfirmModal}
        onConfirm={handleDirectChallengeStart}
        onCancel={() => navigate("/")}
        onClose={() => setShowConfirmModal(false)}
        message={`Are you ready to start the ${challenge?.name} challenge?`}
      />
    </div>
  );
};

export default ExamComponent;
