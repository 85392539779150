import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const TokenPage: React.FC = () => {
  const { currentUser, loginWithToken } = useAuth();
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [inputToken, setInputToken] = useState("");
  const [loginMessage, setLoginMessage] = useState<{
    type: "success" | "error";
    text: string;
  } | null>(null);

  // For generating a token (when user is logged in)
  useEffect(() => {
    if (!currentUser && !showTokenInput) {
      // If not logged in and not showing token input, redirect to home
      navigate("/");
      return;
    }

    if (currentUser) {
      const fetchToken = async () => {
        try {
          setLoading(true);

          // Get the ID token directly from the Firebase user object
          // This is the bearer token used in API calls
          const idToken = await currentUser.getIdToken(true);
          console.log("Got bearer token, length:", idToken.length);

          setToken(idToken);
          setError(null);
        } catch (err) {
          console.error("Error getting bearer token:", err);
          setError("Failed to get authentication token. Please try again.");
          setToken("");
        } finally {
          setLoading(false);
        }
      };

      fetchToken();
    }
  }, [currentUser, navigate, showTokenInput]);

  const copyToClipboard = () => {
    if (!token) return;

    try {
      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = token;

      // Make the textarea out of viewport
      textarea.style.position = "fixed";
      textarea.style.left = "-999999px";
      textarea.style.top = "-999999px";
      document.body.appendChild(textarea);

      // Select and copy
      textarea.focus();
      textarea.select();

      const successful = document.execCommand("copy");
      document.body.removeChild(textarea);

      if (successful) {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      } else {
        // Fallback to navigator.clipboard if execCommand fails
        navigator.clipboard
          .writeText(token)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
          })
          .catch((err) => {
            console.error("Failed to copy token:", err);
            setError(
              "Failed to copy token to clipboard. Please select and copy manually.",
            );
          });
      }
    } catch (err) {
      console.error("Failed to copy token:", err);
      setError(
        "Failed to copy token to clipboard. Please select and copy manually.",
      );
    }
  };

  const handleTokenLogin = async () => {
    if (!inputToken.trim()) {
      setLoginMessage({
        type: "error",
        text: "Please enter a token",
      });
      return;
    }

    if (!loginWithToken) {
      setLoginMessage({
        type: "error",
        text: "Token login is not available",
      });
      return;
    }

    try {
      setLoginMessage({
        type: "success",
        text: "Verifying token...",
      });

      const success = await loginWithToken(inputToken);

      if (success) {
        setLoginMessage({
          type: "success",
          text: "Login successful! Redirecting...",
        });

        // Redirect to home page after successful login
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoginMessage({
          type: "error",
          text: "Invalid or expired token. Please try again.",
        });
      }
    } catch (err) {
      console.error("Error logging in with token:", err);
      setLoginMessage({
        type: "error",
        text: "Error logging in. Please try again.",
      });
    }
  };

  // Toggle between token generation and token login
  const toggleMode = () => {
    setShowTokenInput(!showTokenInput);
    setLoginMessage(null);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-white shadow-lg rounded-lg p-6">
        {/* Header section */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold mb-2">
            {showTokenInput ? "Login with Token" : "Authentication Token"}
          </h1>
          <p className="text-gray-600">
            {showTokenInput
              ? "Enter your authentication token to log in without Google Auth"
              : "Generate a token for CLI authentication or to log in on restricted devices"}
          </p>
        </div>

        {/* Mode toggle */}
        <div className="mb-6">
          <button
            type="button"
            onClick={toggleMode}
            className="text-blue-600 hover:text-blue-800 underline text-sm"
          >
            {showTokenInput
              ? "Generate a new token instead"
              : "Need to log in with a token?"}
          </button>
        </div>

        {/* Token generation section */}
        {!showTokenInput && (
          <div>
            <p className="mb-4">
              Use this token to authenticate the Grind CLI tool or to log in on
              devices where Google Auth is restricted.
            </p>

            {loading ? (
              <div className="flex justify-center my-8">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600" />
              </div>
            ) : error ? (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="h-6 w-6 text-red-500 mr-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      role="img"
                      aria-label="Error icon"
                    >
                      <title>Error</title>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>{error}</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="token"
                  >
                    Your Token:
                  </label>
                  <div className="flex">
                    <textarea
                      id="token"
                      readOnly
                      value={token || ""}
                      className="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-mono text-sm h-10 resize-none overflow-hidden"
                    />
                    <button
                      type="button"
                      onClick={copyToClipboard}
                      className={`${copied ? "bg-green-500" : "bg-blue-500"} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline flex items-center`}
                      disabled={!token}
                    >
                      {copied ? (
                        <>
                          <svg
                            className="h-5 w-5 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            role="img"
                            aria-label="Success icon"
                          >
                            <title>Success</title>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          Copied!
                        </>
                      ) : (
                        <>
                          <svg
                            className="h-5 w-5 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            role="img"
                            aria-label="Copy icon"
                          >
                            <title>Copy</title>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                            />
                          </svg>
                          Copy
                        </>
                      )}
                    </button>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    This token will expire after 1 hour
                  </p>
                </div>

                <div className="mt-6">
                  <h2 className="text-lg font-semibold mb-2">CLI Command</h2>
                  <div className="bg-gray-100 p-3 rounded font-mono text-sm overflow-x-auto break-all">
                    grind auth login --token "{token}"
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    You can copy this entire command and paste it in your
                    terminal
                  </p>
                </div>

                <div className="mt-6 border-t pt-4">
                  <h2 className="text-lg font-semibold mb-2">
                    Share with Others
                  </h2>
                  <p className="text-gray-600 mb-2">
                    You can share this token with someone who needs to access
                    the site from a restricted device:
                  </p>
                  <ol className="list-decimal pl-5 text-gray-600">
                    <li>
                      Have them go to{" "}
                      <span className="font-semibold">
                        {window.location.origin}/token-login
                      </span>
                    </li>
                    <li>
                      They can paste this token to log in without Google Auth
                    </li>
                    <li>Remember that this token will expire after 1 hour</li>
                  </ol>
                </div>
              </>
            )}
          </div>
        )}

        {/* Token login section */}
        {showTokenInput && (
          <div>
            <p className="mb-4">
              Enter the authentication token you received to log in without
              Google Auth.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="input-token"
              >
                Authentication Token:
              </label>
              <input
                id="input-token"
                type="text"
                value={inputToken}
                onChange={(e) => setInputToken(e.target.value)}
                placeholder="Paste your token here"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-mono"
              />
            </div>

            {loginMessage && (
              <div
                className={`${loginMessage.type === "error" ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700"} p-3 rounded mb-4`}
              >
                {loginMessage.text}
              </div>
            )}

            <button
              type="button"
              onClick={handleTokenLogin}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Log In
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenPage;
