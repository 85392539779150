import type {
  Challenge,
  ChallengeData,
  ChallengeRunResponse,
  ChallengeRunsResponse,
  EnhancedChallengeRun,
  ProblemDetails,
} from "shared-types";
import { makeAPICall } from "../api/apiClient";

class ChallengeService {
  async getChallenge(challengeId: string): Promise<ChallengeData> {
    const result = await makeAPICall<{ id: string }, Challenge>(
      "challengeApi-get",
      { id: challengeId },
    );
    if (!result.exists || !result.challenge) {
      throw new Error("Challenge not found");
    }

    // Get full problem details
    const problemIds = result.challenge.problems
      .map((p) => p.problemId)
      .join(",");
    const problemsResult = await makeAPICall<
      { problemIds: string },
      Array<{
        id: string;
        exists: boolean;
        details?: ProblemDetails;
      }>
    >("problemApi-get", { problemIds });

    // Map problems back to challenge structure
    const problemDetails = result.challenge.problems.map((ref) => {
      const problem = problemsResult.find((p) => p.id === ref.problemId);
      if (!problem?.exists || !problem.details) {
        throw new Error(`Problem ${ref.problemId} not found`);
      }
      return {
        ...ref,
        ...problem.details,
      };
    });

    return {
      ...result.challenge,
      problems: problemDetails,
    };
  }

  async getChallengeRun(
    challengeRunId: string,
  ): Promise<EnhancedChallengeRun | null> {
    const result = await makeAPICall<{ id: string }, ChallengeRunResponse>(
      "challengeRunsApi-get",
      { id: challengeRunId },
    );
    if (!result.exists || !result.challengeRun) {
      return null;
    }
    return result.challengeRun;
  }

  async listChallengeRuns(
    startDate: string,
    endDate: string,
    limit: number,
  ): Promise<EnhancedChallengeRun[]> {
    const result = await makeAPICall<
      { startDate: string; endDate: string; limit: number },
      ChallengeRunsResponse
    >("challengeRunsApi-list", { startDate, endDate, limit });
    return result.challengeRuns;
  }
}

export const challengeService = new ChallengeService();
