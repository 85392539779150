import {
  Circle,
  Download,
  Eraser,
  MinusSquare,
  MousePointer,
  Paintbrush,
  Pencil,
  Square,
  Trash2,
  Type,
} from "lucide-react";

export const TOOLS = [
  { id: "pointer", Icon: MousePointer, label: "Pointer" },
  { id: "pencil", Icon: Pencil, label: "Pencil" },
  { id: "line", Icon: MinusSquare, label: "Line" },
  { id: "rectangle", Icon: Square, label: "Rectangle" },
  { id: "circle", Icon: Circle, label: "Circle" },
  { id: "text", Icon: Type, label: "Text" },
  { id: "eraser", Icon: Eraser, label: "Eraser" },
] as const;

export const LINE_WIDTH_OPTIONS = [
  { value: "1", label: "1px" },
  { value: "3", label: "3px" },
  { value: "5", label: "5px" },
  { value: "8", label: "8px" },
] as const;

export const SNAP_THRESHOLD = 20; // Distance in pixels for point snapping
