import type React from "react";
import type { PracticeSession } from "shared-types";

interface PracticeDetailsProps {
  session: PracticeSession;
  countdown: string; // Kept for API compatibility
  loadingZoom: boolean;
  sessionHasEnded: boolean;
}

const PracticeDetails: React.FC<PracticeDetailsProps> = ({
  session,
  loadingZoom,
  sessionHasEnded,
}) => {
  // Countdown display has been moved to Practice.tsx

  return (
    <div className="mb-6">
      <div className="grid grid-cols-12 mb-2">
        <div className="col-span-3 font-semibold text-gray-700">Focus:</div>
        <div className="col-span-9">{session.focus}</div>
      </div>

      <div className="grid grid-cols-12 mb-2">
        <div className="col-span-3 font-semibold text-gray-700">Zoom link:</div>
        <div className="col-span-9">
          {loadingZoom ? (
            <span className="text-gray-600 italic">
              Loading zoom link...
              <span className="inline-block w-5 relative">
                <span className="absolute animate-pulse">...</span>
              </span>
            </span>
          ) : sessionHasEnded && session.zoomLink ? (
            <span className="text-gray-600">{session.zoomLink}</span>
          ) : session.zoomLink ? (
            <a
              href={session.zoomLink}
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {session.zoomLink}
            </a>
          ) : (
            <span className="text-gray-600 italic">Not available yet</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PracticeDetails;
