import type React from "react";
import { useEffect, useState } from "react";
import type {
  ImageData,
  ProblemDetails,
  TimestampedResponse,
} from "shared-types";
import LatexRenderer from "./LatexRenderer";

interface ShortTextAnswerProblemViewProps {
  problem: ProblemDetails & { problemId: string; label: string };
  onUpdateAnswer: (answer: string) => void;
  onSubmit: (answer: string) => void;
  onSkip: () => void;
  currentAnswer: string;
  responses: TimestampedResponse[];
}

const ShortTextAnswerProblemView: React.FC<ShortTextAnswerProblemViewProps> = ({
  problem,
  onUpdateAnswer,
  onSubmit,
  onSkip,
  currentAnswer,
  responses,
}) => {
  const imageData: ImageData | undefined = problem.image;
  const [answer, setAnswer] = useState("");

  // Update answer when currentAnswer changes
  useEffect(() => {
    setAnswer(currentAnswer || "");
  }, [currentAnswer]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAnswer(value);
    onUpdateAnswer(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (answer.trim() !== "") {
        onSubmit(answer.trim());
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (answer.trim() !== "") {
      onSubmit(answer.trim());
    }
  };

  // Get visual state based on response history
  const getAnswerClass = () => {
    if (responses.length < 2) return "";

    const lastResponse = responses[responses.length - 1];
    const secondLastResponse = responses[responses.length - 2];

    if (lastResponse.answer === secondLastResponse.answer) {
      return "bg-green-100 border-green-500"; // Confirmed answer
    }
    return "bg-blue-100 border-blue-500"; // Latest answer
  };

  return (
    <div className="bg-white p-4 rounded shadow">
      <strong className="text-lg">Problem {problem.label}:</strong>
      <div className="mt-2">
        <LatexRenderer latex={problem.statement} />
      </div>
      {imageData && (
        <div className="mt-4 flex justify-center">
          <img
            src={imageData.url}
            alt={imageData.alt}
            width={imageData.width}
            height={imageData.height}
            className="max-w-full h-auto"
            loading="lazy"
          />
        </div>
      )}
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-4">
          <label htmlFor="answer-input" className="block font-medium mb-2">
            Enter your answer:
          </label>
          <input
            id="answer-input"
            type="text"
            value={answer}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className={`w-full h-12 text-lg font-mono border rounded px-3
              focus:outline-none focus:ring-2 focus:ring-blue-500
              ${getAnswerClass()}`}
          />
          <p className="mt-2 text-sm text-gray-600">
            Type your answer and press Enter to submit.
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
              transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            disabled={answer.trim() === ""}
          >
            Submit Answer
          </button>
          <button
            type="button"
            onClick={onSkip}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition-colors"
          >
            Skip
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShortTextAnswerProblemView;
